import {
  Button,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Component } from "react";
import "./enquire.css";
import { Api } from "../../../../api/api";
interface State {
  loading: boolean;
  name: string;
  contactNumber: string;
  email: string;
  enquire: string;
}
interface Properties {
  api?: any;
  className?: string;
}

class Enquire extends React.Component<Properties, State> {
  private api:Api;
  constructor(props: Properties) {
    super(props);
    this.state = {
      loading: false,
      name: "",
      contactNumber: "",
      email: "",
      enquire: "",
    };
    this.api = new Api();
  }

  public async componentDidMount() {}

  private sendMessage = async () => {
    await this.api.sendMessage(
      this.state.name,
      this.state.contactNumber,
      this.state.email,
      this.state.enquire
    );
  };

  private updateForm (value: string, part: string)  {
    switch (part) {
      case "name":
        this.setState({ name: value });
        break;
      case "contactNumber":
        this.setState({ contactNumber: value });
        break;
      case "email":
        this.setState({ email: value });
        break;
      case "enquire":
        this.setState({ enquire: value });
        break;
    }
  };

  render() {
    return (
      <div className={`enquire_container ${this.props.className}`}>
        <div className="enquire_bg"></div>
        <div className="enquire_header">
          <Typography>ENQUIRE NOW</Typography>
        </div>
        <div className="enquire_inputContainer">
          <TextField
          
            onChange={(e:any) => this.updateForm(e.target.value, "name")}
            className="enquire_input"
            variant="outlined"
            label="NAME & SURNAME"
          ></TextField>
        </div>
        <div className="enquire_inputContainer">
          <TextField
            onChange={(e:any) => this.updateForm(e.target.value, "contactNumber")}
            className="enquire_input"
            variant="outlined"
            label="CONTACT NUMBER"
          ></TextField>
        </div>
        <div className="enquire_inputContainer">
          <TextField
            onChange={(e:any) => this.updateForm(e.target.value, "email")}
            className="enquire_input"
            variant="outlined"
            label="EMAIL ADDRESS"
          ></TextField>
        </div>

        <div className="enquire_inputContainer">
          <select
            className="enquire_select"
            onChange={(e:any) => this.updateForm(e.target.value, "enquire")}
          >
            <option value="">I WANT TO FIND OUT MORE ABOUT</option>
            <option value="COACHING">COACHING</option>
            <option value="COACHING APP">COACHING APP</option>
            <option value="MERCHANDISE">MERCHANDISE</option>
            <option value="QUALIFYING AS A COACH">QUALIFYING AS A COACH</option>
          </select>
        </div>
        <div className="enquire_buttonContainer">
          <Button className="enquire_button" onClick={this.sendMessage}>
            SUBMIT
          </Button>
        </div>
      </div>
    );
  }
}

export default Enquire;
