import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "./shop.css";
import { Typography } from "@material-ui/core";
import PageContent from "../pagecontent/pagecontent";
import BottomBar from "../bottombar/bottombar";
import ShopBlock from "./components/shopblock/shopblock";

interface State {
  loading: boolean;
}
interface Properties {
  api?: any;
  inEditMode: boolean;
}

class Shop extends React.Component<Properties, State> {
  constructor(props: Properties) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public async componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="shop_main">
          <div className="shop_headerContainer">
            <Typography className="shop_header">Shop</Typography>
            <div className="shop_headerTextContainer">
              <Typography className="shop_headerContact">
                For orders or more information contact : Charmaine
              </Typography>
              <Typography className="shop_headerContact">
                Tel : 082 870 6388
              </Typography>
              <Typography className="shop_headerContact">
                Email : charmainemul@mweb.co.za
              </Typography>
            </div>
          </div>
          <div className="shop_container">
            <ShopBlock
              className="shop_block"
              buttonText="Cycling Shirt (Short Sleeve)"
              image={require("../../content/shop/shorttop.jpg")}
              inEditMode={this.props.inEditMode}
            ></ShopBlock>
            <ShopBlock
              className="shop_block"
              buttonText="Cycling Shirt (Long Sleeve)"
              image={require("../../content/shop/longtopfront.jpg")}
              inEditMode={this.props.inEditMode}
            ></ShopBlock>

            <ShopBlock
              className="shop_block"
              buttonText="Freestyle Shirt (Short Sleeve)"
              image={require("../../content/shop/freestyleshort.jpg")}
              inEditMode={this.props.inEditMode}
            ></ShopBlock>
            <ShopBlock
              className="shop_block"
              buttonText="Freestyle Shirt (Long Sleeve)"
              image={require("../../content/shop/freestylelong.jpg")}
              inEditMode={this.props.inEditMode}
            ></ShopBlock>

            <ShopBlock
              className="shop_block"
              buttonText="Arm-warmers"
              image={require("../../content/shop/armwarmer.jpg")}
              inEditMode={this.props.inEditMode}
            ></ShopBlock>

            {/* <ShopBlock
            className="shop_block"
              buttonText="SHOP T-SHIRTS"
              image={require("../../content/gal3.jpg")}
              inEditMode={this.props.inEditMode}
            ></ShopBlock> */}
            <ShopBlock
              className="shop_block"
              buttonText="Cap"
              image={require("../../content/shop/cap.jpg")}
              inEditMode={this.props.inEditMode}
            ></ShopBlock>
            <ShopBlock
              className="shop_block"
              buttonText="Gillet"
              image={require("../../content/shop/gillet.jpg")}
              inEditMode={this.props.inEditMode}
            ></ShopBlock>
            <ShopBlock
              className="shop_block"
              buttonText="Buff"
              image={require("../../content/shop/buff.jpg")}
              inEditMode={this.props.inEditMode}
            ></ShopBlock>
            <ShopBlock
              className="shop_block"
              buttonText="Socks"
              image={require("../../content/shop/socks.jpg")}
              inEditMode={this.props.inEditMode}
            ></ShopBlock>
            {/* <ShopBlock
            className="shop_block"
              buttonText="SHOP OTHER"
              image={require("../../content/gal3.jpg")}
              inEditMode={this.props.inEditMode}
            ></ShopBlock> */}
          </div>
        </div>
        <BottomBar></BottomBar>
      </React.Fragment>
    );
  }
}

export default Shop;
