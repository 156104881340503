import React, { Component } from "react";
import "./main.css";
import {
  ThemeProvider,
  createMuiTheme,
  StylesProvider,
} from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "../home/home";
import About from "../about/about";
import Admin from "../admin/admin";
import Coaching from "../coaching/coaching";
import Qualify from "../qualify/qualify";
import { Session } from "../../types";
import Shop from "../shop/shop";
import CoachingApp from "../coachingapp/coachingapp";
import More from "../more/more";
import Blog from "../blog/blog";
import TrainingCamps from "../coaching/trainingcamps/trainingcamps";
import QualifyOutline from "../qualify/components/qualifyoutline/qualifyoutline";
import QualifyFees from "../qualify/components/qualifyfees/qualifyfees";
import QualifyLogin from "../qualify/components/qualifylogin/qualifylogin";
import QualifySchedule from "../qualify/components/qualifyschedule/qualifyschedule";
import Contact from "../contact/contact";
import Gallery from "../gallery/gallery";
import Terms from "../terms/terms";
import CoachingSessions from "../coachingsessions/coachingsessions";
import { Library } from "../library/library";

interface MainState {
  loading: boolean;
}

interface MainProperties {
  api?: any;
  onNavigate: (location: string) => void;
  session: Session;
  activeMenu: string;
}

class App extends React.Component<MainProperties, MainState> {
  constructor(props: MainProperties) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  public async componentDidMount() {
    this.setState({ loading: false });
  }

  private handleNav = (location: string) => {
    this.props.onNavigate(location);
  };

  private renderArea = () => {
    switch (this.props.activeMenu) {
      case "home":
      case "":
      case "/":
        return (
          <Home
            api={this.props.api}
            onNavigate={this.handleNav}
            inEditMode={this.props.session.inEditMode}
          ></Home>
        );
      case "library":
        return <Library></Library>;
      case "coaching":
        return <Coaching inEditMode={this.props.session.inEditMode}></Coaching>;

      case "coaching_trainingcamps":
        return (
          <TrainingCamps
            inEditMode={this.props.session.inEditMode}
          ></TrainingCamps>
        );
      case "coachingsessions":
        return <CoachingSessions></CoachingSessions>;
      case "qualifyhow":
        return (
          <Qualify
            onNavigate={this.handleNav}
            inEditMode={this.props.session.inEditMode}
          ></Qualify>
        );
      case "qualifyoutline":
        return (
          <QualifyOutline
            onNavigate={this.handleNav}
            inEditMode={this.props.session.inEditMode}
          ></QualifyOutline>
        );
      case "qualifyfee":
        return (
          <QualifyFees
            onNavigate={this.handleNav}
            inEditMode={this.props.session.inEditMode}
          ></QualifyFees>
        );
      case "qualifydate":
        return (
          <QualifySchedule
            onNavigate={this.handleNav}
            inEditMode={this.props.session.inEditMode}
          ></QualifySchedule>
        );
      case "qualifylogin":
        return (
          <QualifyLogin
            onNavigate={this.handleNav}
            inEditMode={this.props.session.inEditMode}
          ></QualifyLogin>
        );
      case "shop":
        return <Shop inEditMode={this.props.session.inEditMode}></Shop>;
      case "coachingapp":
        // window.open("https://veloworld.dedicated.co.za");
        return (
          <div className="main_popupcontainer">
            <div
              className="main_popupmessage"
              onClick={() => window.open("https://veloworld.dedicated.co.za")}
            >
              Click Here to launch the Coaching App
            </div>
            <iframe
              className="main_coachappframe"
              src="https://www.youtube.com/embed/p2xpVza1g04?enablejsapi=1&origin=https://yourdomain.com&showinfo=0&iv_load_policy=3&modestbranding=1&theme=light&color=white&rel=0"
              allowFullScreen
            ></iframe>
          </div>
        );
      case "contact":
        return <Contact inEditMode={this.props.session.inEditMode}></Contact>;
      case "gallery":
        return <Gallery inEditMode={this.props.session.inEditMode}></Gallery>;
      case "blog":
        return <Blog inEditMode={this.props.session.inEditMode}></Blog>;
      case "admin":
        return <Admin></Admin>;
      case "terms":
        return <Terms inEditMode={this.props.session.inEditMode}></Terms>;
      case "indemnity":
        return (
          <iframe
            className="main_indemnityiframe"
            src={require("../../content/indemnity.pdf")}
          />
        );
      case "about":
        return <About inEditMode={this.props.session.inEditMode}></About>;
      default:
        return (
          <Home
            api={this.props.api}
            onNavigate={this.handleNav}
            inEditMode={this.props.session.inEditMode}
          ></Home>
        );
    }
  };

  render() {
    let component = this.renderArea();
    if (this.state.loading) {
      return <div></div>;
    }
    return <div className="main_main">{component}</div>;
  }
}

export default App;
