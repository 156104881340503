import React, { Component, Fragment, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Typography, Paper, Modal, TextField, Dialog } from "@material-ui/core";
import { Api } from "../../api/api";
import { BlogPost } from "../../types";
import "regenerator-runtime/runtime";
// import Editor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
// import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment.js";
// import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat.js";
// import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote.js";
// import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold.js";
// import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials.js";
// import FontBackgroundColor from "@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js";
// import FontColor from "@ckeditor/ckeditor5-font/src/fontcolor.js";
// import FontFamily from "@ckeditor/ckeditor5-font/src/fontfamily.js";
// import FontSize from "@ckeditor/ckeditor5-font/src/fontsize.js";
// import Heading from "@ckeditor/ckeditor5-heading/src/heading.js";
// import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight.js";
// import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js";
// import Image from "@ckeditor/ckeditor5-image/src/image.js";
// import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption.js";
// import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize.js";
// import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle.js";
// import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar.js";
// import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload.js";
// import Indent from "@ckeditor/ckeditor5-indent/src/indent.js";
// import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock.js";
// import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic.js";
// import Link from "@ckeditor/ckeditor5-link/src/link.js";
// import List from "@ckeditor/ckeditor5-list/src/list.js";
// import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed.js";
// import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph.js";
// import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
// import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
// import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough.js";
// import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript.js";
// import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript.js";
// import Table from "@ckeditor/ckeditor5-table/src/table.js";
// import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
// import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
// import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar.js";
// import TextTransformation from "@ckeditor/ckeditor5-typing/src/texttransformation.js";
// import TodoList from "@ckeditor/ckeditor5-list/src/todolist";
// import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline.js";
import "./blog.css";
import moment from "moment";

declare const __APIURL__: string;
interface State {
  loading: boolean;
  posts: BlogPost[];
  editPost: BlogPost | null;
  editContent: any;
  editor: any;
}
interface Properties {
  api?: any;
  inEditMode: boolean;
}

class Blog extends React.Component<Properties, State> {
  private _editor: any;
  private editorRef: any = React.createRef();
  // private editorConfiguration = {
  //   plugins: [
  //     Alignment,
  //     Autoformat,
  //     BlockQuote,
  //     Bold,
  //     Essentials,
  //     FontBackgroundColor,
  //     FontColor,
  //     FontFamily,
  //     FontSize,
  //     Heading,
  //     Highlight,
  //     HorizontalLine,
  //     Image,
  //     ImageCaption,
  //     ImageResize,
  //     ImageStyle,
  //     ImageToolbar,
  //     ImageUpload,
  //     Indent,
  //     IndentBlock,
  //     Italic,
  //     Link,
  //     List,
  //     MediaEmbed,
  //     Paragraph,
  //     PasteFromOffice,
  //     SimpleUploadAdapter,
  //     Strikethrough,
  //     Subscript,
  //     Superscript,
  //     Table,
  //     TableCellProperties,
  //     TableProperties,
  //     TableToolbar,
  //     TextTransformation,
  //     TodoList,
  //     Underline,
  //   ],
  //   toolbar: [
  //     "alignment",
  //     "blockQuote",
  //     "bold",
  //     "selectAll",
  //     "undo",
  //     "redo",
  //     "fontBackgroundColor",
  //     "fontColor",
  //     "fontFamily",
  //     "fontSize",
  //     "heading",
  //     "highlight",
  //     "horizontalLine",
  //     "imageTextAlternative",
  //     "imageStyle:full",
  //     "imageUpload",
  //     "indent",
  //     "outdent",
  //     "italic",
  //     "link",
  //     "numberedList",
  //     "bulletedList",
  //     "mediaEmbed",
  //     "strikethrough",
  //     "subscript",
  //     "insertTable",
  //     "tableColumn",
  //     "tableRow",
  //     "mergeTableCells",
  //     "tableCellProperties",
  //     "tableProperties",
  //     "underline",
  //   ],
  //   simpleUpload: {
  //     uploadUrl: __APIURL__ + "/veloworldza/imageUpload",
  //     headers: {
  //       Authorization: "Bearer " + localStorage.getItem("veloworldzaToken"),
  //     },
  //   },
  //   image: {
  //     toolbar: [
  //       "imageTextAlternative",
  //       "|",
  //       "imageStyle:alignLeft",
  //       "imageStyle:alignCenter",
  //       "imageStyle:alignRight",
  //       "imageStyle:full",
  //     ],

  //     styles: ["alignLeft", "alignCenter", "alignRight", "full"],
  //   },
  // };

  constructor(props: Properties) {
    super(props);
    this.state = {
      loading: false,
      posts: [],
      editPost: null,
      editContent: "",
      editor: null,
    };
  }

  public async componentDidMount() {
    const api = new Api();
    const content = await api.getBlogPosts();
    this.setState({ posts: content });
  }

  componentWillUnmount() {}

  private createPost = (): BlogPost => {
    return {
      id: 0,
      title: "",
      content: "",
      date: new Date(),
    };
  };

  private addPost = async () => {
    this.editPost(this.createPost());
  };

  private savePost = async () => {
    const api = new Api();
    const post = Object.assign({}, this.state.editPost);
    post.content = this._editor.getData();
    post.date = new Date();
    const posts = await api.saveBlogPost(post);
    this.setState({ posts: posts, editPost: null });
  };

  private cancelPost = async () => {
    this.setState({ editPost: null });
  };

  private editPost = (post: BlogPost) => {
    // this.setState({ editPost: post }, () => {
    //   let config = Object.assign({}, this.editorConfiguration);
    //   if (!this.props.inEditMode) {
    //     config.toolbar = [];
    //   }
    //   Editor.create(document.querySelector("#editpost-editor"), config)
    //     .then((editor: any) => {
    //       editor.isReadOnly = !this.props.inEditMode;
    //       const toolbarContainer = document.querySelector(
    //         "#editpost-toolbar-container"
    //       );
    //       if (toolbarContainer) {
    //         toolbarContainer.appendChild(editor.ui.view.toolbar.element);
    //       }
    //       this._editor = editor;
    //       editor.setData(post.content);
    //     })
    //     .catch((error: any) => {
    //       console.error(error);
    //     });
    //   this.editorRef.current.scrollTo(0, 0);
    // });
  };

  private deletePost = async (id: number) => {
    const api = new Api();
    const posts = await api.deleteBlogPost(id);
    this.setState({ posts: posts, editPost: null });
  };

  private updateTitle = (e: any) => {
    const post = Object.assign({}, this.state.editPost);
    post.title = e.target.value;
    this.setState({ editPost: post });
  };

  private renderPost = (post: BlogPost) => {
    return (
      <div key={`post_${post.id}`} className="blog-post">
        <div>
          <Typography variant="h6">{post.title}</Typography>{" "}
          {this.props.inEditMode ? (
            <div>
              <Button
                className="blog-editpostbutton"
                color="primary"
                variant="outlined"
                onClick={this.editPost.bind(this, post)}
              >
                Edit This Post
              </Button>
              <Button
                variant="outlined"
                className="blog-deleteButton"
                onClick={this.deletePost.bind(this, post.id)}
              >
                Delete This Post
              </Button>
            </div>
          ) : null}
        </div>
        <div>
          <Typography>{moment(post.date).format("DD MMM, YYYY")}</Typography>
        </div>
        <div className="blog-postitem">
          <div
            className="blog-readOnlyEditor ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline ck-blurred"
            dangerouslySetInnerHTML={{
              __html: post.content,
            }}
          ></div>
          <div className="blog-readMoreContainer">
            <Button
              variant="outlined"
              color="primary"
              onClick={this.editPost.bind(this, post)}
            >
              Read More
            </Button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="pagecontent-main">
        <Paper className="pagecontent-paper" elevation={15}>
          <Typography color="primary">BLOG</Typography>
          {this.props.inEditMode && !this.state.editPost ? (
            <div className="blog-addpostbutton">
              <Button
                variant="outlined"
                className="blog-addButton"
                onClick={this.addPost}
              >
                Add Post
              </Button>
            </div>
          ) : null}
          {this.state.editPost ? (
            <div>
              {this.props.inEditMode ? (
                <div className="blog-editrow">
                  <div>Title</div>
                  <div>
                    <TextField
                      className="blog-titlefield"
                      onChange={this.updateTitle}
                      value={this.state.editPost.title}
                    ></TextField>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <Button
                    onClick={this.cancelPost}
                    variant="outlined"
                    color="secondary"
                  >
                    Back
                  </Button>
                  <Typography variant="h6">
                    {this.state.editPost.title}
                  </Typography>
                </React.Fragment>
              )}
              {!this.props.inEditMode ? (
                <Typography>
                  {moment(this.state.editPost.date).format("DD MMM, YYYY")}
                </Typography>
              ) : null}
              <div className="blog-editrow">
                {this.props.inEditMode ? <div>Post Content</div> : null}
                <div id="editpost-toolbar-container"></div>
                <div id={"editpost-editor"} ref={this.editorRef}></div>
              </div>

              <div className="blog-editbuttonrow">
                {this.props.inEditMode ? (
                  <React.Fragment>
                    <Button
                      className="blog-savepostbutton"
                      onClick={this.savePost}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                    <Button
                      onClick={this.cancelPost}
                      variant="outlined"
                      color="secondary"
                    >
                      Cancel
                    </Button>
                  </React.Fragment>
                ) : (
                  <Button
                    onClick={this.cancelPost}
                    variant="outlined"
                    color="secondary"
                  >
                    Back
                  </Button>
                )}
              </div>
            </div>
          ) : (
            this.state.posts.map(this.renderPost)
          )}
        </Paper>
      </div>
    );
  }
}

export default Blog;
