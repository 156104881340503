import React from "react";
import "./countdown.css";
import moment from "moment";
import { Typography } from "@material-ui/core";

interface CountdownProperties {
  targetDateTime: Date;
}
interface CountdownState {
  loading: boolean;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export class Countdown extends React.Component<
  CountdownProperties,
  CountdownState
> {
  constructor(props: CountdownProperties) {
    super(props);
    this.state = {
      loading: true,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  public async componentDidMount() {
    this.getDuration();
    await this.setState({ loading: false });
    window.setInterval(() => this.getDuration(), 1000);
  }

  private getDuration = () => {
    if (!this.props.targetDateTime) return;
    const now = moment(new Date());
    const end = moment(this.props.targetDateTime);
    var duration = moment.duration(end.diff(now));
    
    this.setState({
      days: parseInt(duration.asDays().toString(),10),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    });
  };

  private formatNumber = (value: number, max: number) => {
    if (value < 0 || value > max) {
      return "";
    }
    if (value.toString().length == 1) {
      return "0" + value;
    }
    return value.toString();
  };

 

  private renderDisplay = () => {
    if (
      this.state.days <= 0 &&
      this.state.hours <= 0 &&
      this.state.minutes <= 0 &&
      this.state.seconds <= 0
    ) {
      return (
        <div className="events-doneBlock">
          <Typography variant="h5">Done!</Typography>
        </div>
      );
    }
    return (
      <div className="countdown_container">

        <div className="countdown-countDownComponent">
          <div>
            <Typography className="countdown_number">
              {this.formatNumber(this.state.days, 999)}
            </Typography>
          </div>
          <div className="countdown-desc">
            <Typography>days</Typography>
          </div>
        </div>
        <div className="countdown-countDownComponent">
          <div>
            <Typography  className="countdown_number">
              {this.formatNumber(this.state.hours, 24)}
            </Typography>
          </div>
          <div className="countdown-desc">
            <Typography>hrs</Typography>
          </div>
        </div>
        <div className="countdown-countDownComponent">
          <div>
            <Typography  className="countdown_number">
              {this.formatNumber(this.state.minutes , 60)}
            </Typography>
          </div>
          <div className="countdown-desc">
            <Typography>mins</Typography>
          </div>
        </div>
        <div className="countdown-countDownComponent">
          <div>
            <Typography  className="countdown_number">
              {this.formatNumber(this.state.seconds , 60)}
            </Typography>
          </div>
          <div className="countdown-desc">
            <Typography>secs</Typography>
          </div>
        </div>
      </div>
    );
  };

  public render(): JSX.Element {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    return this.renderDisplay();
  }
}
