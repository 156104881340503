import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "./qualifyschedule.css";
import { Typography } from "@material-ui/core";
import PageContent from "../../../pagecontent/pagecontent";
import BottomBar from "../../../bottombar/bottombar";
import SocialMediaInsert from "../../../socialmediainsert/socialmediainsert";
import Enquire from "../../../home/components/enquire/enquire";
import QualifyButtons from "../qualifybuttons/qualifybuttons";

interface State {
  loading: boolean;
}
interface Properties {
  api?: any;
  inEditMode: boolean;
  onNavigate: (location:string)=>void;
}

class QualifySchedule extends React.Component<Properties, State> {
  constructor(props: Properties) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public async componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="qualifyschedule_main">
          <div>
            <Typography className="qualifyschedule_header">
            Course dates and scheduling
            </Typography>
          </div>
          <div className="qualifyschedule_text">
            <Typography className="qualifyschedule_contentText">
            The Veloworld <b>NQF Level 4 Course in Cycling Coaching</b> program runs between 2 and 3 months of
blended learning, with in-class sessions (or via internet), self-study and practical work. We plan to
run 2 courses in 2021, in April 2021, and then in September 2021.<br></br><br></br>
Please check back here regularly for exact dates, or send us an email to <a href='mailto:learning@veloworldza.com'>learning@veloworldza.com</a>,
and we will get back to you.
            </Typography>
            <SocialMediaInsert></SocialMediaInsert>
            <QualifyButtons onNavigate={this.props.onNavigate}></QualifyButtons>
          </div>
        </div>
        <Enquire className="qualifyschedule_enquire" />
        <img
          className="qualifyschedule_image"
          src={require("../../../../content/gal2.jpg")}
        ></img>

        <BottomBar></BottomBar>
      </React.Fragment>
    );
  }
}

export default QualifySchedule;
