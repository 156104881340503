import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "./qualifyoutline.css";
import { Typography } from "@material-ui/core";
import PageContent from "../../../pagecontent/pagecontent";
import BottomBar from "../../../bottombar/bottombar";
import SocialMediaInsert from "../../../socialmediainsert/socialmediainsert";
import Enquire from "../../../home/components/enquire/enquire";
import QualifyButtons from "../qualifybuttons/qualifybuttons";

interface State {
  loading: boolean;
}
interface Properties {
  api?: any;
  inEditMode: boolean;
  onNavigate: (location:string)=>void;
}

class QualifyOutline extends React.Component<Properties, State> {
  constructor(props: Properties) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public async componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="qualifyoutline_main">
          <div>
            <Typography className="qualifyoutline_header">
              Course outline and accreditation
            </Typography>
          </div>
          <div className="qualifyoutline_text">
            <Typography className="qualifyoutline_contentText">
              Veloworld ZA (PTY) Ltd is registered with The Culture, Art,
              Tourism, Hospitality, and Sport Sector Education and Training
              Authority (CATHSSETA) and offers an accredited NQF Level 4 Course
              in Cycling Coaching. Qualification title: Further Education and
              Training Certificate: Sport Coaching: Juniors/Beginners.
              Qualification ID: 64369<br></br><br></br>
              Course outline is as follows:<br></br>
              Module 1: Oral Communication – 10 Credits<br></br>
              Module 2: Written Communication – 30 Credits<br></br>
              Module 3: Numeracy Skills – 16 Credits<br></br>
              Module 4: The Sport Coaching Environment – 7 Credits<br></br>
              Module 5: Science of Sport coaching – 23 Credits<br></br>
              Module 6: The Sports Coach – 31 Credits<br></br>
              Module 7: Community Involvement – 36 Credits<br></br><br></br>
              Total Credits: 153<br></br><br></br>
              NQF Level 4
            </Typography>
            <SocialMediaInsert></SocialMediaInsert>
            <QualifyButtons  onNavigate={this.props.onNavigate}></QualifyButtons>
          </div>
        </div>
        <Enquire className="qualifyoutline_enquire" />
        <img
          className="qualifyoutline_image"
          src={require("../../../../content/courseoutline.jpg")}
        ></img>

        <BottomBar></BottomBar>
      </React.Fragment>
    );
  }
}

export default QualifyOutline;
