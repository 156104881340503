import React, { Component, Fragment } from "react";
import Button from "@material-ui/core/Button";
import "./pagecontent.css";
import { Typography, Paper } from "@material-ui/core";
import { Api } from "../../api/api";
import Loader from "../loader/loader";
import "regenerator-runtime/runtime";
// import Editor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
// import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment.js";
// import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat.js";
// import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote.js";
// import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold.js";
// import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials.js";
// import FontBackgroundColor from "@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js";
// import FontColor from "@ckeditor/ckeditor5-font/src/fontcolor.js";
// import FontFamily from "@ckeditor/ckeditor5-font/src/fontfamily.js";
// import FontSize from "@ckeditor/ckeditor5-font/src/fontsize.js";
// import Heading from "@ckeditor/ckeditor5-heading/src/heading.js";
// import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight.js";
// import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js";
// import Image from "@ckeditor/ckeditor5-image/src/image.js";
// import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption.js";
// import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize.js";
// import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle.js";
// import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar.js";
// import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload.js";
// import Indent from "@ckeditor/ckeditor5-indent/src/indent.js";
// import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock.js";
// import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic.js";
// import Link from "@ckeditor/ckeditor5-link/src/link.js";
// import List from "@ckeditor/ckeditor5-list/src/list.js";
// import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed.js";
// import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph.js";
// import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
// import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
// import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough.js";
// import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript.js";
// import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript.js";
// import Table from "@ckeditor/ckeditor5-table/src/table.js";
// import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
// import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
// import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar.js";
// import TextTransformation from "@ckeditor/ckeditor5-typing/src/texttransformation.js";
// import TodoList from "@ckeditor/ckeditor5-list/src/todolist";
// import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline.js";
import moment from "moment";
declare const __APIURL__: string;
interface State {
  loading: boolean;
  editor: any;
  nativeEditor: any;
}
interface Properties {
  api?: any;
  inEditMode: boolean;
  page: string;
}

class PageContent extends React.Component<Properties, State> {
  private _editor: any;
  private editorRef: any = React.createRef();
  // private editorConfiguration = {
  //   plugins: [
  //     Alignment,
  //     Autoformat,
  //     BlockQuote,
  //     Bold,
  //     Essentials,
  //     FontBackgroundColor,
  //     FontColor,
  //     FontFamily,
  //     FontSize,
  //     Heading,
  //     Highlight,
  //     HorizontalLine,
  //     Image,
  //     ImageCaption,
  //     ImageResize,
  //     ImageStyle,
  //     ImageToolbar,
  //     ImageUpload,
  //     Indent,
  //     IndentBlock,
  //     Italic,
  //     Link,
  //     List,
  //     MediaEmbed,
  //     Paragraph,
  //     PasteFromOffice,
  //     SimpleUploadAdapter,
  //     Strikethrough,
  //     Subscript,
  //     Superscript,
  //     Table,
  //     TableCellProperties,
  //     TableProperties,
  //     TableToolbar,
  //     TextTransformation,
  //     TodoList,
  //     Underline,
  //   ],
  //   toolbar: [
  //     "alignment",
  //     "blockQuote",
  //     "bold",
  //     "selectAll",
  //     "undo",
  //     "redo",
  //     "fontBackgroundColor",
  //     "fontColor",
  //     "fontFamily",
  //     "fontSize",
  //     "heading",
  //     "highlight",
  //     "horizontalLine",
  //     "imageTextAlternative",
  //     "imageStyle:full",
  //     "imageUpload",
  //     "indent",
  //     "outdent",
  //     "italic",
  //     "link",
  //     "numberedList",
  //     "bulletedList",
  //     "mediaEmbed",
  //     "strikethrough",
  //     "subscript",
  //     "insertTable",
  //     "tableColumn",
  //     "tableRow",
  //     "mergeTableCells",
  //     "tableCellProperties",
  //     "tableProperties",
  //     "underline",
  //   ],
  //   simpleUpload: {
  //     uploadUrl: __APIURL__ + "/veloworldza/imageUpload",
  //     headers: {
  //       Authorization: "Bearer " + localStorage.getItem("veloworldzaToken"),
  //     },
  //   },
  //   image: {
  //     toolbar: [
  //       "imageTextAlternative",
  //       "|",
  //       "imageStyle:alignLeft",
  //       "imageStyle:alignCenter",
  //       "imageStyle:alignRight",
  //       "imageStyle:full",
  //     ],

  //     styles: ["alignLeft", "alignCenter", "alignRight", "full"],
  //   },
  // };
  constructor(props: Properties) {
    super(props);
    this.state = {
      loading: false,
      editor: null,
      nativeEditor: null,
    };
  }

  public async componentDidMount() {
    const api = new Api();
    const content = await api.getContent(this.props.page);
    // let config = Object.assign({}, this.editorConfiguration);
    // if (!this.props.inEditMode) {
    //   config.toolbar = [];
    // }
    // Editor.create(document.querySelector("#content"), config)
    //   .then((editor: any) => {
    //     editor.isReadOnly = !this.props.inEditMode;
    //     const toolbarContainer = document.querySelector(
    //       "#editpost-toolbar-container"
    //     );
    //     if (toolbarContainer) {
    //       toolbarContainer.appendChild(editor.ui.view.toolbar.element);
    //     }
    //     this._editor = editor;
    //     editor.setData(content);
    //   })
    //   .catch((error: any) => {
    //     console.error(error);
    //   });
  }

  private saveContent = async () => {
    const api = new Api();
    await api.saveContent(this.props.page, this._editor.getData());
  };

  componentWillUnmount() {}

  render() {
    return (
      <div className="pagecontent-main">
        <Paper className="pagecontent-paper" elevation={15}>
          {this.props.inEditMode ? (
            <div>
              <Button
                onClick={this.saveContent}
                variant="outlined"
                color="primary"
              >
                Save
              </Button>
            </div>
          ) : null}
          <div
            className="ae-placeholder"
            contentEditable="true"
            data-placeholder=""
            id="content"
          >
            <Loader></Loader>
          </div>
        </Paper>
      </div>
    );
  }
}

export default PageContent;
