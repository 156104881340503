import React, { Component, Fragment } from "react";
import Button from "@material-ui/core/Button";
import "./admin.css";
import { Api } from "../../api/api";

interface State {}
interface Properties {}

class Admin extends React.Component<Properties, State> {
  constructor(props: Properties) {
    super(props);
    this.state = {};
  }

  public async componentDidMount() {
    const username = prompt("Please Log in. Username:", "");
    try {
      if (username) {
        const password = prompt("Password:", "");
        if (password) {
          const api = new Api();
          const user = await api.login(username, password);
          if (user) {
            let sessionObj = null;
            var session = localStorage.getItem("session");
            if (session) {
              sessionObj = JSON.parse(session);
              sessionObj.isAdmin = true;
            } else {
              sessionObj = { isAdmin: true, inEditMode: false };
            }
            localStorage.setItem("session", JSON.stringify(sessionObj));
            this.setState({ session: sessionObj });
          }
        }
      }
    } catch (e) {
      window.location.href = "home";
    } finally {
      window.location.href = "home";
    }
  }

  componentWillUnmount() {}

  render() {
    return <div className="admin-main"></div>;
  }
}

export default Admin;
