import React, { Component, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import "./coachingsessions.css";
import { StylesProvider, Typography } from "@material-ui/core";
import PageContent from "../pagecontent/pagecontent";
declare global {
  interface Window {
    gapi: any;
  }
}

const CoachingSessions = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  const openBooking = () => {
    window.open("https://veloworld.secure.simplybook.me/", "_blank");
  };

  return (
    <div className="coachingsessions-content">
      <div className="coachingsessions_contact">
        <Typography className="coachingsessions_contacttext">
          A new window has been opened to the booking site. <br></br>
          If a new page did not open, please disable popup blockers for this
          site and click{" "}
          <a href="javascript:void(0);" onClick={() => openBooking()}>
            here to book.
          </a>
          <br></br>
          <br></br>
          <br></br>
          Please contact Marcel (
          <a href="mailto:marcel@velowordza.com">marcel@velowordza.com</a>) to
          book a session
        </Typography>
      </div>
      {/* <iframe
        className="coachingsessions_calendar"
        // src="https://calendar.google.com/calendar/embed?src=v5o9si8ego3tt5hdokao8ar230%40group.calendar.google.com&ctz=Africa%2FJohannesburg"
        src="https://veloworld.secure.simplybook.me/"
        style={{ border: 0 }}
        scrolling="no"
      ></iframe> */}
    </div>
  );
};

export default CoachingSessions;
