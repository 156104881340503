import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "./contact.css";
import { Typography } from "@material-ui/core";
import PageContent from "../pagecontent/pagecontent";
import BottomBar from "../bottombar/bottombar";
import SocialMediaInsert from "../socialmediainsert/socialmediainsert";
import Enquire from "../home/components/enquire/enquire";

interface State {
  loading: boolean;
}
interface Properties {
  api?: any;
  inEditMode: boolean;
}

class Contact extends React.Component<Properties, State> {
  constructor(props: Properties) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public async componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        
        <Enquire className="contact_enquire" />
       

        <BottomBar></BottomBar>
      </React.Fragment>
    );
  }
}

export default Contact;
