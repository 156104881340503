import React, { Component, createRef } from "react";
import Button from "@material-ui/core/Button";
import MainMenu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "./menu.css";
import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import { Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { Session } from "../../types";

interface State {
  loading: boolean;
  anchorEl: string;
  activeMenu: string;
}

interface Properties {
  api?: any;
  session: any;
  onSetEditMode: (edit: boolean) => void;
  onMenuSelected: (menuItem: string) => void;
}

class Menu extends React.Component<Properties, State> {
  constructor(props: Properties) {
    super(props);
    this.state = {
      loading: true,
      anchorEl: "",
      activeMenu: "",
    };
  }

  public async componentDidMount() {
    await this.setState({ loading: false });
  }

  public async componentDidUpdate(props: any, other: any) {
    console.log("menu updated");
    console.log("isStudent:" + props.session.isStudent);
  }

  private handleHomeClick = (event: any) => {
    this.setState({ activeMenu: "home" });
    this.props.onMenuSelected("home");
  };
  private handleHomeHover = (event: any) => {
    this.handleClose();
    this.setState({ activeMenu: "home" });
  };

  private handleAppClick = (event: any) => {
    this.setState({ activeMenu: "coachingapp", anchorEl: event.currentTarget });
    this.props.onMenuSelected("coachingapp");
  };
  private handleAppHover = (event: any) => {
    this.handleClose();
    this.setState({ activeMenu: "coachingapp", anchorEl: event.currentTarget });
  };

  private handleCoachingClick = (event: any) => {
    this.setState({ activeMenu: "coaching" });
    this.props.onMenuSelected("coaching");
  };
  private handleCoachingHover = (event: any) => {
    this.handleClose();
    this.setState({ activeMenu: "coaching" });
  };

  private handleCoachingSessionsClick = (event: any) => {
    this.setState({ activeMenu: "coachingsessions" });
    this.props.onMenuSelected("coachingsessions");
    window.open("https://veloworld.simplybook.me/v2/#", "_blank");
  };
  private handleCoachingSessionsHover = (event: any) => {
    this.handleClose();
    this.setState({ activeMenu: "coachingsessions" });
  };

  private handleQualifyClick = (event: any) => {
    this.setState({ activeMenu: "qualify", anchorEl: "qualify" });
  };
  private handleQualifyHover = (event: any) => {
    //this.handleClose();
    //this.setState({ activeMenu: "qualify", anchorEl: event.currentTarget });
  };

  private handleStudentClick = (event: any) => {
    this.setState({ activeMenu: "student", anchorEl: "student" });
  };
  private handleStudentHover = (event: any) => {
    //this.handleClose();
    //this.setState({ activeMenu: "qualify", anchorEl: event.currentTarget });
  };
  private handleStudentMoreLibraryClick = () => {
    this.handleClose();
    this.setState({ activeMenu: "library" });
    this.props.onMenuSelected("library");
  };
  private handleStudentMoreLogoutClick = () => {
    this.handleClose();
    localStorage.setItem(
      "session",
      JSON.stringify({ isAdmin: false, isStudent: false })
    );
    this.setState({ activeMenu: "logout" });
    this.props.onMenuSelected("logout");
  };

  private handleShopClick = (event: any) => {
    this.setState({ activeMenu: "shop" });
    this.props.onMenuSelected("shop");
  };
  private handleShopHover = (event: any) => {
    this.handleClose();
    this.setState({ activeMenu: "shop" });
  };

  private handleMoreClick = (event: any) => {
    this.handleClose();
    this.setState({ activeMenu: "more", anchorEl: "more" });
    //this.props.onMenuSelected("more");
  };

  private handleClose = () => {
    this.setState({ anchorEl: "", activeMenu: "" });
  };

  private onSetEditMode = () => {
    this.props.onSetEditMode(!this.props.session.inEditMode);
  };

  private handleQualifyMoreHowClick = () => {
    this.handleClose();
    this.setState({ activeMenu: "qualify" });
    this.props.onMenuSelected("qualifyhow");
  };

  private handleQualifyMoreAccredClick = () => {
    this.handleClose();
    this.setState({ activeMenu: "qualify" });
    this.props.onMenuSelected("qualifyoutline");
  };

  private handleQualifyMoreFeeClick = () => {
    this.handleClose();
    this.setState({ activeMenu: "qualify" });
    this.props.onMenuSelected("qualifyfee");
  };

  private handleQualifyMoreDateClick = () => {
    this.handleClose();
    this.setState({ activeMenu: "qualify" });
    this.props.onMenuSelected("qualifydate");
  };

  private handleQualifyMoreLoginClick = () => {
    this.handleClose();
    this.setState({ activeMenu: "qualify" });
    this.props.onMenuSelected("qualifylogin");
  };

  private handleMoreContactClick = () => {
    this.handleClose();
    this.setState({ activeMenu: "more" });
    this.props.onMenuSelected("contact");
  };

  private handleMoreGalleryClick = () => {
    this.handleClose();
    this.setState({ activeMenu: "more" });
    this.props.onMenuSelected("gallery");
  };

  private handleMoreBlogClick = () => {
    this.handleClose();
    this.setState({ activeMenu: "more" });
    this.props.onMenuSelected("blog");
  };

  private handleMoreAboutClick = () => {
    this.handleClose();
    this.setState({ activeMenu: "more" });
    this.props.onMenuSelected("about");
  };

  private handleMobileClick = () => {
    this.handleClose();
    this.setState({ activeMenu: "more" });
    window.open("./veloworld.apk");
  };

  private handleMoreTcClick = () => {
    this.handleClose();
    this.setState({ activeMenu: "more" });
    this.props.onMenuSelected("terms");
  };

  private handleMoreIndemnityClick = () => {
    this.handleClose();
    this.setState({ activeMenu: "more" });
    this.props.onMenuSelected("indemnity");
  };

  render() {
    return (
      <div className="menu-mainLine">
        <div className="menu-main">
          <div>
            <Button
              id="home-menuItem"
              className="menu-button"
              aria-controls="home-menu"
              aria-haspopup="true"
              onClick={this.handleHomeClick}
              onMouseOver={this.handleHomeHover}
              color={this.state.activeMenu === "home" ? "primary" : "secondary"}
            >
              <Typography variant="h4" className="menu-buttonText">
                HOME
              </Typography>
            </Button>
          </div>

          {this.props.session.isStudent && (
            <div>
              <Button
                id="student-menuItem"
                className="menu-button"
                aria-controls="student-menu"
                aria-haspopup="true"
                onMouseOver={this.handleStudentHover}
                onClick={this.handleStudentClick}
                color={
                  this.state.activeMenu === "student" ? "primary" : "secondary"
                }
              >
                <div className="menu_arrowItem">
                  <Typography variant="h4" className="menu-buttonText">
                    STUDENT
                  </Typography>
                  <img
                    className="menu_arrow"
                    src={require("../../content/menarrow.png")}
                  ></img>
                </div>
              </Button>
              {this.state.anchorEl === "student" && (
                <div className="menu_studentmore">
                  <div onClick={this.handleStudentMoreLibraryClick}>
                    {" "}
                    <div className="menu_hoverItem">
                      <Typography variant="h5"> LIBRARY</Typography>{" "}
                    </div>
                  </div>
                  <div onClick={this.handleStudentMoreLogoutClick}>
                    {" "}
                    <div className="menu_hoverItem">
                      <Typography variant="h5"> LOGOUT</Typography>{" "}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <div>
            <Button
              id="app-menuItem"
              className="menu-button"
              aria-controls="app-menu"
              aria-haspopup="true"
              onMouseOver={this.handleAppHover}
              onClick={this.handleAppClick}
              color={
                this.state.activeMenu === "coachingapp"
                  ? "primary"
                  : "secondary"
              }
            >
              <Typography variant="h4" className="menu-buttonText">
                COACHING APP
              </Typography>
            </Button>
          </div>
          <div>
            <Button
              id="coaching-menuItem"
              className="menu-button"
              aria-controls="coaching-menu"
              aria-haspopup="true"
              onClick={this.handleCoachingClick}
              onMouseOver={this.handleCoachingHover}
              color={
                this.state.activeMenu === "coaching" ? "primary" : "secondary"
              }
            >
              <Typography variant="h4" className="menu-buttonText">
                COACHING
              </Typography>
            </Button>
          </div>
          <div>
            <Button
              id="qualify-menuItem"
              className="menu-button"
              aria-controls="qualify-menu"
              aria-haspopup="true"
              onMouseOver={this.handleQualifyHover}
              onClick={this.handleQualifyClick}
              color={
                this.state.activeMenu === "qualify" ? "primary" : "secondary"
              }
            >
              <div className="menu_arrowItem">
                <Typography variant="h4" className="menu-buttonText">
                  QUALIFY AS A COACH
                </Typography>
                <img
                  className="menu_arrow"
                  src={require("../../content/menarrow.png")}
                ></img>
              </div>
            </Button>
            {this.state.anchorEl === "qualify" && (
              <div className="menu_qualifymore">
                <div onClick={this.handleQualifyMoreHowClick}>
                  {" "}
                  <div className="menu_hoverItem">
                    <Typography variant="h5">
                      {" "}
                      HOW TO QUALIFY AS A COACH
                    </Typography>{" "}
                  </div>
                </div>
                <div onClick={this.handleQualifyMoreAccredClick}>
                  {" "}
                  <div className="menu_hoverItem">
                    <Typography variant="h5">
                      COURSE OUTLINE AND ACCREDITATION
                    </Typography>{" "}
                  </div>
                </div>
                <div onClick={this.handleQualifyMoreFeeClick}>
                  {" "}
                  <div className="menu_hoverItem">
                    <Typography variant="h5">COURSE FEES</Typography>{" "}
                  </div>
                </div>
                <div onClick={this.handleQualifyMoreDateClick}>
                  {" "}
                  <div className="menu_hoverItem">
                    <Typography variant="h5">
                      COURSE DATES & SCHEDULING
                    </Typography>{" "}
                  </div>
                </div>
                <div onClick={this.handleQualifyMoreLoginClick}>
                  {" "}
                  <div className="menu_hoverItem">
                    <Typography variant="h5">LOG IN AS A STUDENT</Typography>{" "}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            <Button
              id="coachingsessions-menuItem"
              className="menu-button"
              aria-controls="coachingsessions-menu"
              aria-haspopup="true"
              onClick={this.handleCoachingSessionsClick}
              onMouseOver={this.handleCoachingSessionsHover}
              color={
                this.state.activeMenu === "coachingsessions"
                  ? "primary"
                  : "secondary"
              }
            >
              <Typography variant="h4" className="menu-buttonText">
                COACHING SESSIONS
              </Typography>
            </Button>
          </div>
          <div>
            <Button
              id="shop-menuItem"
              className="menu-button"
              aria-controls="shop-menu"
              aria-haspopup="true"
              onMouseOver={this.handleShopHover}
              onClick={this.handleShopClick}
              color={this.state.activeMenu === "shop" ? "primary" : "secondary"}
            >
              <Typography variant="h4" className="menu-buttonText">
                SHOP
              </Typography>
            </Button>
          </div>
          <div>
            <Button
              id="more-menuItem"
              className="menu-button"
              aria-controls="more-menu"
              aria-haspopup="true"
              onClick={this.handleMoreClick}
              color={this.state.activeMenu === "more" ? "primary" : "secondary"}
            >
              <div className="menu_arrowItem">
                <Typography variant="h4" className="menu-buttonText">
                  MORE
                </Typography>
                <img
                  className="menu_arrow"
                  src={require("../../content/menarrow.png")}
                ></img>
              </div>
            </Button>
            {this.state.anchorEl === "more" && (
              <div className="menu_moremore">
                <div onClick={this.handleMoreContactClick}>
                  <div className="menu_hoverItem">
                    <Typography variant="h5">CONTACT</Typography>
                  </div>
                </div>
                <div onClick={this.handleMoreGalleryClick}>
                  <div className="menu_hoverItem">
                    <Typography variant="h5">GALLERY</Typography>{" "}
                  </div>
                </div>
                <div onClick={this.handleMoreBlogClick}>
                  <div className="menu_hoverItem">
                    <Typography variant="h5">BLOG</Typography>{" "}
                  </div>
                </div>
                <div onClick={this.handleMoreTcClick}>
                  <div className="menu_hoverItem">
                    <Typography variant="h5">T&C'S</Typography>{" "}
                  </div>
                </div>
                <div onClick={this.handleMoreIndemnityClick}>
                  <div className="menu_hoverItem">
                    <Typography variant="h5">INDEMNITY</Typography>{" "}
                  </div>
                </div>
                <div onClick={this.handleMoreAboutClick}>
                  <div className="menu_hoverItem">
                    <Typography variant="h5">ABOUT</Typography>{" "}
                  </div>
                </div>
                <div onClick={this.handleMobileClick}>
                  <div className="menu_hoverItem">
                    <Typography variant="h5">MOBILE APP</Typography>{" "}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {this.props.session.isAdmin ? (
          <div className="menu-editbutton" onClick={this.onSetEditMode}>
            <EditIcon color="primary"></EditIcon>
          </div>
        ) : null}
        {this.props.session.inEditMode ? (
          <div className="menu-editnotification">
            <Typography>Edit mode active</Typography>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Menu;
