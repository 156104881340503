import axios from "axios";
import { BlogPost } from "../types";
declare const __APIURL__: string;
axios.defaults.withCredentials = true;
axios.defaults.headers.common["Authorization"] =
  localStorage.getItem("veloworldzaToken");
export class Api {
  public async login(username: string, password: string): Promise<any> {
    try {
      const user = await axios.post(__APIURL__ + "/veloworldza/login", {
        username: username,
        password: password,
      });
      const token = user.data.token;
      localStorage.setItem("veloworldzaToken", token);
      return user.data;
    } catch (err) {
      return null;
    }
  }

  public async studentlogin(username: string, password: string): Promise<any> {
    try {
      const user = await axios.post(__APIURL__ + "/veloworldza/studentlogin", {
        username: username,
        password: password,
      });
      const token = user.data.token;
      localStorage.setItem("veloworldzaToken", token);
      return user.data;
    } catch (err) {
      return null;
    }
  }

  public async saveContent(page: string, content: any): Promise<void> {
    await axios.post(__APIURL__ + "/veloworldza/saveContent", {
      page: page,
      content: content,
    });
    return;
  }

  public async getContent(page: string): Promise<any> {
    const data = await axios.post(__APIURL__ + "/veloworldza/getContent", {
      page: page,
    });
    return data.data;
  }

  public async getEvents(): Promise<any> {
    const data = await axios.get(__APIURL__ + "/veloworldza/events");
    return data.data;
  }

  public async getNextEvent(): Promise<any> {
    const data = await axios.get(__APIURL__ + "/veloworldza/nextevent");
    return data.data;
  }

  public async addEvent(event: any): Promise<any> {
    const data = await axios.post(__APIURL__ + "/veloworldza/event", event);
    return data.data;
  }

  public async deleteEvent(id: Number): Promise<any> {
    const data = await axios.delete(__APIURL__ + "/veloworldza/event/" + id);
    return data.data;
  }

  public async getBlogPosts(): Promise<BlogPost[]> {
    const data = await axios.get(__APIURL__ + "/veloworldza/blogPosts");
    return data.data;
  }

  public async getTop5BlogPosts(): Promise<BlogPost[]> {
    const data = await axios.get(__APIURL__ + "/veloworldza/top5BlogPosts");
    return data.data;
  }

  public async saveBlogPost(post: BlogPost): Promise<BlogPost[]> {
    const data = await axios.post(__APIURL__ + "/veloworldza/blogpost", post);
    return data.data;
  }

  public async deleteBlogPost(id: Number): Promise<BlogPost[]> {
    const data = await axios.delete(__APIURL__ + "/veloworldza/blogPost/" + id);
    return data.data;
  }

  public async sendMessage(
    name: string,
    contactNumber: string,
    email: string,
    enquire: string
  ) {
    const data = await axios.post(__APIURL__ + "/veloworldza/sendMessage", {
      name: name,
      contactNumber: contactNumber,
      email: email,
      enquire: enquire,
    });
    return data.data;
  }

  public async getLibraryDocuments() {
    var token = localStorage.getItem("veloworldzaToken");
    console.log("getting library");
    axios.defaults.headers.common["Authorization"] = token;
    var result = await axios.get(`${__APIURL__}/studentlibrary`);
    return result.data;
  }
}
