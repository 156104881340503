import React, { useEffect, useState } from "react";
import "./library.css";
import Rodal from "rodal";
import { Api } from "../../api/api";
import ReactPlayer from "react-player";
declare const __APIURL__: string;
export const Library = () => {
  const [documents, setDocuments] = useState([]) as any[];
  const [viewDocument, setViewDocument] = useState("");
  const [viewVideo, setViewVideo] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const api = new Api();
  useEffect(() => {
    setIsLoading(true);
    api.getLibraryDocuments().then((result: any) => {
      setDocuments(result);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <div>Loading documents, please wait...</div>;
  }

  const openDocument = async (path: string) => {
    const id = path.replace("library/", "");
    setViewDocument(id);
  };

  const openLink = async (path: string) => {
    window.open(path);
  };

  const openVideo = async (path: string) => {
    setViewVideo(path);
  };

  const getIcon = (doc: any) => {
    let extension = "";
    console.log("docpath=" + doc.path);
    console.log("startswith?" + doc.path.startsWith("http"));
    if (doc.path.startsWith("http")) {
      extension = "http";
    }
    if (
      doc.path.includes("youtube.com") ||
      doc.path.includes("drive.google.com")
    ) {
      extension = "vid";
    } else {
      var extensionArr = doc.filename.split(".");
      if (extensionArr.length > 1 && extension === "") {
        extension = extensionArr[1];
      }
    }
    switch (extension) {
      case "pdf":
        return (
          <img
            className={"library_icon"}
            src={require("../../content/pdf.png")}
          ></img>
        );
      case "doc":
      case "docx":
        return (
          <img
            className={"library_icon"}
            src={require("../../content/docx.png")}
          ></img>
        );
      case "ppt":
      case "pptx":
        return (
          <img
            className={"library_icon"}
            src={require("../../content/pptx.png")}
          ></img>
        );
      case "xls":
      case "xlsx":
        return (
          <img
            className={"library_icon"}
            src={require("../../content/xls.png")}
          ></img>
        );
      case "jpg":
      case "jpeg":
      case "png":
      case "bmp":
        return (
          <img
            className={"library_icon"}
            src={require("../../content/img.png")}
          ></img>
        );
      case "http":
        return (
          <img
            className={"library_icon"}
            src={require("../../content/http.png")}
          ></img>
        );
      case "vid":
        return (
          <img
            className={"library_icon"}
            src={require("../../content/vid.png")}
          ></img>
        );
      default:
        return (
          <img
            className={"library_icon"}
            src={require("../../content/file.png")}
          ></img>
        );
    }
  };

  const handleClick = (doc:any) => {
    if (doc.path.includes("youtube")) {
      openVideo(doc.path);
      return;
    }
    if (doc.path.startsWith("http")) {
      openLink(doc.path);
      return;
    }
    openDocument(doc.path);
  };

  const renderIconCell = (doc:any) => {
    if (doc.path.includes("youtube") || doc.path.includes("drive.google")) {
      return (
        <td style={{ width: "40px" }} onClick={() => handleClick(doc)}>
          {getIcon(doc)}
        </td>
      );
    }
    if (doc.path.startsWith("http")) {
      return (
        <td style={{ width: "40px" }} onClick={() => handleClick(doc)}>
          {getIcon(doc)}
        </td>
      );
    }
    return (
      <td style={{ width: "40px" }} onClick={() => handleClick(doc)}>
        {getIcon(doc)}
      </td>
    );
  };


  const renderDocuments = () => {
    const elements = [];

    for (const doc of documents) {
      elements.push(
        <tr
          className={"library_row"}
          data-tip
          data-for={`badgetip${doc.id}`}
          key={doc.id}
        >
           {renderIconCell(doc)}
          <td onClick={() => handleClick(doc)}>{doc.filename}</td>
        </tr>
      );
    }
    return (
      <table className={"library_doctable"}>
        <tbody>{elements}</tbody>
      </table>
    );
  };

  return (
    <div className={"library_main"}>
      <div>
        <div className={"library_header"}>LIBRARY</div>
        <div className={"library_container"}>{renderDocuments()}</div>
      </div>
      {viewDocument !== "" && (
        <Rodal
          customStyles={{ width: "90%", height: "90%" }}
          visible={viewDocument}
          onClose={() => setViewDocument("")}
        >
          <iframe
            className={"library_docview"}
            src={`${__APIURL__}/librarydocument/${viewDocument}`}
          />
        </Rodal>
      )}
       {viewVideo && (
        <Rodal
          customStyles={{ width: "90%", height: "90%" }}
          visible={viewVideo.length > 0}
          onClose={() => setViewVideo("")}
        >
          <ReactPlayer
            controls
            width={"100%"}
            height={"100%"}         
            url={viewVideo}
          />
        </Rodal>
      )}
    </div>
  );
};
