import { Typography } from "@material-ui/core";
import React from "react";
import "./gallerybox.css";

interface State {
  loading: boolean;
}
interface Properties {
  api?: any;
  className?: string;
  background: any;
  icon: any;
  text: string;
  onClick: () => void;
}

class GalleryBox extends React.Component<Properties, State> {
  constructor(props: Properties) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public async componentDidMount() {}

  render() {
    return (
      <div className={this.props.className} onClick={this.props.onClick}>
        {this.props.background}
        <div className="gallerybox_bar">
          <span className="gallerybox_iconcontainer">{this.props.icon}</span><Typography>{this.props.text}</Typography>
        </div>
      </div>
    );
  }
}

export default GalleryBox;
