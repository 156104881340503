import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "./shopblock.css";
import { Typography } from "@material-ui/core";
import PageContent from "../../../pagecontent/pagecontent";
import BottomBar from "../../../bottombar/bottombar";

interface State {
  loading: boolean;
}
interface Properties {
  api?: any;
  className?: string;
  inEditMode: boolean;
  buttonText: string;
  image: string;
}

class ShopBlock extends React.Component<Properties, State> {
  constructor(props: Properties) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public async componentDidMount() {}

  render() {
    return (
      <div className={`shopblock_main ${this.props.className}`}>
       
          <img src={this.props.image}></img>
        
        <Button className="shopblock_button">{this.props.buttonText}</Button>
      </div>
    );
  }
}

export default ShopBlock;
