import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "./qualifyfees.css";
import { Typography } from "@material-ui/core";
import PageContent from "../../../pagecontent/pagecontent";
import BottomBar from "../../../bottombar/bottombar";
import SocialMediaInsert from "../../../socialmediainsert/socialmediainsert";
import Enquire from "../../../home/components/enquire/enquire";
import QualifyButtons from "../qualifybuttons/qualifybuttons";

interface State {
  loading: boolean;
}
interface Properties {
  api?: any;
  inEditMode: boolean;
  onNavigate: (location:string)=>void;
}

class QualifyFees extends React.Component<Properties, State> {
  constructor(props: Properties) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public async componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="qualifyfees_main">
          <div>
            <Typography className="qualifyfees_header">
           Course Fees
            </Typography>
          </div>
          <div className="qualifyfees_text">
            <Typography className="qualifyfees_contentText">
            Course fees depend on the size of groups, and also on which of the modules a student can get
credits for. More details regarding course fees will be available soon. Please check in regularly, or
alternatively send us an email to <a href='mailto:learning@veloworldza.com'>learning@veloworldza.com</a>, and we will get back to you.
            </Typography>
            <SocialMediaInsert></SocialMediaInsert>
            <QualifyButtons  onNavigate={this.props.onNavigate}></QualifyButtons>
          </div>
        </div>
        <Enquire className="qualifyfees_enquire" />
        <img
          className="qualifyfees_image"
          src={require("../../../../content/gal2.jpg")}
        ></img>

        <BottomBar></BottomBar>
      </React.Fragment>
    );
  }
}

export default QualifyFees;
