import React from "react";
import "./socialmediainsert.css";
import { Typography } from "@material-ui/core";

interface State {}

interface Properties {}

class SocialMediaInsert extends React.Component<Properties, State> {
  constructor(props: Properties) {
    super(props);
    this.state = {};
  }

  public async componentDidMount() {
    await this.setState({ loading: false });
  }

  private loadInstagram = () => {
    window.open("https://www.instagram.com/veloworldza/");
  };

  private loadFacebook = () => {
    window.open("https://www.facebook.com/veloworldza/");
  };

  render() {
    return (
      <React.Fragment>
        <Typography className="socialmediainsert_socialText">
          FOLLOW US ON SOCIAL MEDIA
        </Typography>
        <div className="socialmediainsert_socialcontainer">
          <img
            onClick={this.loadFacebook}
            className="socialmediainsert_bottomBarLogoFb"
            src={require("../../content/facebook.png")}
          ></img>
          <img
            onClick={this.loadInstagram}
            className="socialmediainsert_bottomBarLogoIn"
            src={require("../../content/insta.png")}
          ></img>
          <Typography variant="body1" className="socialmediainsert_q3">
            @veloworldsa
          </Typography>
        </div>
      </React.Fragment>
    );
  }
}

export default SocialMediaInsert;
