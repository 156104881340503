import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "./trainingcamps.css";
import { Typography } from "@material-ui/core";
import PageContent from "../../pagecontent/pagecontent";

interface State {
  loading: boolean;
}
interface Properties {
  api?: any;
  inEditMode: boolean;
}

class TrainingCamps extends React.Component<Properties, State> {
  constructor(props: Properties) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public async componentDidMount() {}

  render() {
    return (
      <div>
        {" "}
        <PageContent
          inEditMode={this.props.inEditMode}
          page="coaching_trainingcamps"
        ></PageContent>
      </div>
    );
  }
}

export default TrainingCamps;
