import React from "react";
import "./bottombar.css";
import { Typography } from "@material-ui/core";

interface State {

}

interface Properties {
}

class BottomBar extends React.Component<Properties, State> {
  constructor(props: Properties) {
    super(props);
    this.state = {
     

    };
  }

  public async componentDidMount() {
    await this.setState({ loading: false });
  }

  
  private loadInstagram = () => {
    window.open("https://www.instagram.com/veloworldza/");
  };

  private loadFacebook = () => {
    window.open("https://www.facebook.com/veloworldza/");
  };

  render() {
   return (
    <div className="index_bottomBar">
    <div className="index_bottomBarLogoContainer app_bottomBarItem">
      <img
        className="index_bottomBarLogo"
        src={require( "../../content/bottombarlogo.png")}
      ></img>{" "}
      <span dangerouslySetInnerHTML={{ __html: "&copy;" }} />
      <Typography className="bottombar_text1">
        {new Date().getFullYear()} by VELOWORLDZA
      </Typography>
    </div>
    <div className="app_bottomBarItem">
      <Typography variant="body1" className="bottombar_text2">
        "It never gets easier, you just go faster" - Greg Le Mond
      </Typography>
    </div>
    <div className="app_bottomBarItem">
      <Typography variant="body1" className="bottombar_text3">
        Email us on:
        <br />
        <a
          href="mailto:marcel@veloworldza.com"
          className="app_mailLink"
        >
          marcel@veloworldza.com
        </a>
      </Typography>
    </div>
    <div className="app_fbincontainer app_bottomBarItem">
      <Typography variant="body1" className="bottombar_text3">
        Follow us on: <br />
        @veloworldsa
      </Typography>
      <img
        onClick={this.loadFacebook}
        className="index_bottomBarLogoFb"
        src={require("../../content/facebook.png")}
      ></img>
      <img
        onClick={this.loadInstagram}
        className="index_bottomBarLogoIn"
        src={require("../../content/insta.png")}
      ></img>
    </div>
  </div>);
}
}

export default BottomBar;
