import React, { Component } from "react";
import Menu from "./components/menu/menu";
import "./app.css";
import {
  ThemeProvider,
  createMuiTheme,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  BrowserRouter,
} from "react-router-dom";
import Main from "./components/main/main";
import { Session } from "./types";
import Admin from "./components/admin/admin";
import SimpleReactLightbox from "simple-react-lightbox";
import { Api } from "./api/api";
import Rodal from "rodal";
require("./rodal.css");
const theme = createMuiTheme({
  palette: {
    primary: {
      // orange
      main: "rgb(243, 90, 34)",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#ffffff",
    },
  },
});

interface MainState {
  loading: boolean;
  session: any;
  activeMenu: string;
  showLogin: boolean;
  username: string;
  password: string;
  error: string;
  loginSuccess: boolean;
}
interface MainProperties {
  api?: any;
}

class App extends React.Component<MainProperties, MainState> {
  private myRef: any;
  private api: Api;
  constructor(props: MainProperties) {
    super(props);
    this.state = {
      loading: true,
      session: this.createSessionObject(),
      activeMenu: "",
      showLogin: false,
      username: "",
      password: "",
      error: "",
      loginSuccess: false,
    };
    document.title = "Veloworld";
    this.myRef = React.createRef();
    this.api = new Api();
  }

  public async componentDidMount() {
    try {
      var session = localStorage.getItem("session");
      if (session) {
        this.setState({ session: JSON.parse(session) });
      } else {
        var sessionObj = this.createSessionObject();
        this.updateSession(sessionObj);
      }
    } catch (e) {
      return false;
    }
    this.setState({ loading: false });
  }

  private createSessionObject = () => {
    return {
      isAdmin: false,
      inEditMode: false,
      isStudent: false,
    };
  };

  private updateSession = (session: Session) => {
    localStorage.setItem("session", JSON.stringify(session));
    this.setState({ session: session });
  };

  private onSetEditMode = (edit: boolean) => {
    const session = Object.assign({}, this.state.session);
    session.inEditMode = edit;
    this.updateSession(session);
  };

  private onMenuSelected = (menu: string) => {
    this.setState({ activeMenu: menu });
    if (menu === "logout") {
      this.setState({
        session: { isStudent: false, isAdmin: false, inEditMode: false },
      });
    }
    if (menu === "qualifylogin") {
      this.setState({ showLogin: true });
    }
    this.myRef.current.scrollTo(0, 0);
  };

  private login = async () => {
    this.setState({ error: "" });
    var user = await this.api.studentlogin(
      this.state.username,
      this.state.password
    );
    if (user) {
      let sessionObj = null;
      var session = localStorage.getItem("session");
      if (session) {
        sessionObj = JSON.parse(session);
        sessionObj.isStudent = true;
      } else {
        sessionObj = { isStudent: true, inEditMode: false };
      }
      localStorage.setItem("session", JSON.stringify(sessionObj));
      this.setState({
        session: sessionObj,
        loginSuccess: true,
        showLogin: false,
      });
    } else {
      this.setState({ error: "Invalid credentials" });
    }
  };

  render() {
    if (this.state.loading) {
      return <div></div>;
    }
    return (
      <div className="index_main">
        <ThemeProvider theme={theme}>
          <SimpleReactLightbox>
            <div className="app_menuContainer">
              <Menu
                onSetEditMode={this.onSetEditMode}
                onMenuSelected={this.onMenuSelected}
                session={this.state.session}
              ></Menu>
            </div>
            <div className="index_stripe">
              <img
                className="index_sidebar"
                src={require("./content/stripe.png")}
              ></img>
            </div>
            <div className="index_logo">
              <img src={require("./content/logo.png")}></img>
            </div>
            <div className="app_mainContentScrollContainer" ref={this.myRef}>
              <div className="app_mainContentContainer">
                <BrowserRouter>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      component={() => (
                        <Main
                          api={this.api}
                          onNavigate={this.onMenuSelected}
                          activeMenu={this.state.activeMenu}
                          session={this.state.session}
                        ></Main>
                      )}
                    ></Route>
                    <Route path="/admin">
                      <Admin></Admin>
                    </Route>
                  </Switch>
                </BrowserRouter>
              </div>
            </div>
          </SimpleReactLightbox>
        </ThemeProvider>
        <Rodal
          customStyles={{ width: "30%", height: "30%" }}
          visible={this.state.showLogin}
          onClose={() => this.setState({ showLogin: false })}
        >
          <div style={{ padding: "25px" }}>
            <div style={{ width: "100%", marginBottom: "20px" }}>
              <TextField
                onChange={(e: any) =>
                  this.setState({ username: e.target.value })
                }
                className="login_input"
                variant="outlined"
                label="USERNAME"
              ></TextField>
            </div>
            <div>
              <TextField
                onChange={(e: any) =>
                  this.setState({ password: e.target.value })
                }
                className="login_input"
                variant="outlined"
                type="password"
                label="PASSWORD"
              ></TextField>
            </div>
            <div style={{ color: "red" }}>{this.state.error}</div>
            <Button className="enquire_button" onClick={this.login}>
              LOGIN
            </Button>
          </div>
        </Rodal>

        <Rodal
          customStyles={{ width: "30%", height: "30%" }}
          visible={this.state.loginSuccess}
          onClose={() => this.setState({ loginSuccess: false })}
        >
          <div style={{ padding: "25px" }}>
            <Typography>Login Successful!</Typography>
            <Typography>You now have access to the "STUDENT" area.</Typography>
          </div>
        </Rodal>
      </div>
    );
  }
}

export default App;
