import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "./terms.css";
import { Typography } from "@material-ui/core";
import PageContent from "../pagecontent/pagecontent";
import BottomBar from "../bottombar/bottombar";
import SocialMediaInsert from "../socialmediainsert/socialmediainsert";
import Enquire from "../home/components/enquire/enquire";

interface State {
  loading: boolean;
}
interface Properties {
  api?: any;
  inEditMode: boolean;
}

class Terms extends React.Component<Properties, State> {
  constructor(props: Properties) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public async componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="about_main">
          <div>
            <Typography className="about_header">TERMS & CONDITIONS</Typography>
          </div>
          <div className="about_text">
            <Typography className="about_contentText">
              <b>Terms & Conditions for use of this website</b><br></br><br></br> These terms and
              conditions are binding and enforceable against all persons that
              access this website, www.veloworldza.com, or any part thereof (in
              terms of section 11(2) of the Electronic Communications and
              Transactions (ECT) Act 25 of 2002.<br></br><br></br>
              If for some reason you, the
              user of this website, do not agree to the terms and conditions you
              must leave this website now, as any further usage of this website
              will automatically bind you to these terms and conditions.<br></br><br></br>
              1. COPYRIGHT<br></br><br></br>
              1. Copyright 2020 © <b>Veloworld ZA (Pty) Ltd</b>. All rights
              not expressly granted are reserved. To obtain permissions for the
              commercial use of any content on this site contact Veloworld ZA
              (Pty) Ltd at +27 82 577 1080, or via email marcel@veloworldza.com<br></br><br></br>
              2. Users may download, view and print content from this site for
              private and non-commercial purposes only. <br></br><br></br>
              2. INTELLECTUAL PROPERTY RIGHTS<br></br><br></br>
              1.All intellectual property, including but are not limited
              to content, domain names, text, software, photographs, graphics,
              databases, hyperlinks, designs, agreements and other material
              contained in the website are the property of Veloworld ZA (Pty)
              Ltd and as such are protected from infringement by domestic and
              international legislation and treaties.<br></br><br></br>
              3. ELECTRONIC COMMUNICATIONS<br></br><br></br>
              1.When a user visits www.veloworldza.com or sends
              e-mails to Veloworldza, that user consents to receiving
              communications from <b>Veloworld ZA (Pty) Ltd</b> electronically and
              agrees that all agreements, notices, disclosures and other
              communications sent by <b>Veloworld ZA (Pty) Ltd</b> satisfies any legal
              requirements, including but not limited, to the requirement that
              such communications should be “in writing”.<br></br><br></br>
              4. HYPERLINKS<br></br><br></br>
              1. No
              person, business or web site may link to any page on this site
              without the prior written permission of <b>Veloworld ZA (Pty) Ltd</b>.
              Such permission could be obtained from the entity referred to in
              clause 1 hereof.<br></br><br></br>
              2. Hyperlinks provided on this site to other
              sites. These linked websites are not under the control of
              <b>Veloworld ZA (Pty) Ltd</b> and <b>Veloworld ZA (Pty) Ltd</b> therefore does
              not take any responsibility for the privacy practices or the
              content of those sites, or any liability arising from access to
              any website which is linked from this site, or any hyperlink
              contained in a linked website. Your action to link to any such
              website is entirely at your own risk
              <br></br><br></br>
              5. FRAMING<br></br><br></br>
              1. No person,
              business or web site may frame this site or any of the pages on
              this site in any way whatsoever without prior permission. Such
              permission could be obtained from <b>Veloworld ZA (Pty) Ltd</b> referred
              to in clause 1 hereof.<br></br><br></br>
              6. SPIDERS AND CRAWLERS<br></br><br></br>
              1. No person,
              business or web site may use any technology to search and gain any
              information from this site without the prior written permission of
              <b>Veloworld ZA (Pty) Ltd</b>. Such permission could be obtained from the
              person referred to in clause 1 hereof.<br></br><br></br>
              7. DISCLAIMER<br></br><br></br>
              1. Apart from
              the provisions of sections 43(5) and 43(6) of the Electronic
              Communications and Transactions Act, neither Veloworld ZA (Pty)
              Ltd nor any of its agents or representatives shall be liable for
              any damage, loss or liability of whatsoever nature arising from
              the use or inability to use this web site or the services or
              content provided from and through this web site. Furthermore,
              <b>Veloworld ZA (Pty) Ltd</b> makes no representations or warranties,
              implied or otherwise, that, amongst others, the content and
              technology available from this web site are free from errors or
              omissions or that the service will be 100% uninterrupted and error
              free. Users are encouraged to report any possible malfunctions and
              errors to the person referred to in clause 1.
              <br></br><br></br>2. This web site is
              supplied on an “as is” basis and has not been compiled or supplied
              to meet the user’s individual requirements. It is the sole
              responsibility of the user to satisfy itself prior to entering
              into this agreement with <b>Veloworld ZA (Pty) Ltd</b> that the service
              available from and through this web site will meet the user’s
              individual requirements and be compatible with the user’s hardware
              and/or software.<br></br><br></br>
              3. Information, ideas and opinions expressed on
              this site should not be regarded as professional advice or the
              official opinion of <b>Veloworld ZA (Pty) Ltd</b> and users are
              encouraged to consult us personally before taking any course of
              action related to information, ideas or opinions expressed on this
              site.<br></br><br></br>
              8. GOVERNING LAW<br></br><br></br>
              1. This site is hosted, controlled and
              operated from the Republic of South Africa and therefore governed
              by South African law.<br></br><br></br>
              9. PRIVACY<br></br><br></br>
              1. This site collects personal
              and other information from the user when the user uses any
              electronic contact form on the website. The personal information
              that is collected is necessary to act on the communication that
              was sent from the website.<br></br><br></br>
              2. None of the information collected or
              received via the online forms is and will be used for marketing
              purposes, except if the sender requests more information
              concerning the subject matter.<br></br><br></br>
              3. We will not disclose personal
              information to third party without the user’s permission unless:
              we are compelled to do so by law or in terms of a court order; it
              is in the public interest to do so; it is necessary to protect our
              rights.<br></br><br></br>
              10. SECURITY<br></br><br></br>
              1. Any person that delivers or attempts to
              deliver any damaging code to this web site or attempts to gain
              unauthorised access to any page on this web site shall be
              prosecuted and civil damages shall be claimed in the event that
              <b>Veloworld ZA (Pty) Ltd</b> suffers any damage or loss.<br></br><br></br>
              2. The user
              allows <b>Veloworld ZA (Pty) Ltd</b> to take all reasonable steps to
              ensure the integrity and security of the www.veloworldza.com site
              and back-office applications.<br></br><br></br>
              11. CHANGES TO AGREEMENT<br></br><br></br>
              1.
              <b>Veloworld ZA (Pty) Ltd</b> may, in its sole discretion, change this
              agreement or any part thereof at any time without notice.<br></br><br></br>
              12. REMOVAL AND CORRECTION OF CONTENT<br></br><br></br>
              1. Users are encouraged to
              report untrue, inaccurate, defamatory, illegal, infringing and/or
              harmful content available from this website to Veloworld ZA (Pty)
              Ltd. <b>Veloworld ZA (Pty) Ltd</b> undertakes to correct and/or remove
              such content or any part thereof if the person reporting such
              content provides reasonable grounds to prove the alleged nature of
              the content.<br></br><br></br>
              13. DOMICILIUM<br></br><br></br>
              1. <b>Veloworld ZA (Pty) Ltd</b> chooses as
              its domicilium citandi et executandi for all purposes under this
              agreement, whether in respect of court process, notice, or other
              documents or communication of whatsoever nature, 23 Hoheizen
              Crescent, Bellville, 7530.<br></br><br></br>
              14. TERMS AND TERMINATION<br></br><br></br>
              1. This
              agreement shall commence when the user registers on the Veloworld
              ZA (Pty) Ltd site and continues indefinitely until terminated by
              <b>Veloworld ZA (Pty) Ltd</b> in terms of clause 21 here to or for any
              other reason.
              <br></br><br></br><br></br><br></br>
              <b>TERMS AND CONDITIONS FOR ONLINE PURCHASES</b><br></br><br></br>
              1.
              Supplier identification<br></br><br></br>
              www.veloworldza.com is a site operated by
              <b>Veloworld ZA (Pty) Ltd</b>. <b>Veloworld ZA (Pty) Ltd</b> is a cycling
              coaching business operating in South Africa with our registered
              office in Bellville, Cape Town, South Africa. Our main trading
              address is: 23 Hoheizen Crescent, Bellville, 7530, South Africa.
              We are not registered for VAT.<br></br><br></br>
              2. Privacy Policy & Cookies The
              website is owned by <b>Veloworld ZA (Pty) Ltd</b>. Contract execution
              Your personal data will be used to provide the information, goods
              and services offered through our website to you, for billing and
              order fulfilment. Email newsletter • If you sign up to our
              newsletter we may use your email address to send you information
              about products or services. • You can opt out of these at any
              point and you can ask for personal data to stop being recorded at
              any time.<br></br><br></br>
              Cookies & monitoring<br></br><br></br>
              Monitoring is important for your
              consumers to understand what information you are collecting from
              visits. It is important that you stress the reasons why you are
              monitoring this traffic. Remember to mention any 3rd party
              services you are using that may collect cookies also, such as
              Google Analytics. A cookie is a small text file that we store on
              your device. Our website uses cookies to distinguish from other
              users of you website. Cookies also provide us with information
              about how this website is used so we can keep it as up to date,
              relevant and error-free as possible.<br></br><br></br>
              • Strictly necessary cookies
              – These are cookies that are essential to the operation of our
              website. • Analytical/performance cookie – These cookies allow us
              to recognise and count the number of visitors to our website. •
              Functionality cookies – These cookies are used to recognise you
              when you return to our website. • Targeting Cookies – These
              cookies record your visit to our website, the pages you have
              visited and the links you have followed.<br></br><br></br>
              We may monitor traffic to
              our site and collect the following information:<br></br><br></br>
              • The IP address
              of your computer. • The referring website from which you have got
              to our website from.<br></br><br></br>
              The reasons for this are:<br></br><br></br>
              • To make ongoing
              improvements to our website based on this data. • To see our most
              popular sources of business.<br></br><br></br>
              Disclosure of personal data<br></br><br></br>
              We may
              disclose your personal data:<br></br><br></br>
              • If we sell our business.<br></br><br></br>
              • In cases
              where we are required by law to pass on information or if we
              believe action is necessary for fraud, cyber crime or to protect
              the website, rights, personal safety of person/s.<br></br><br></br>
              We may also
              disclose aggregate statistics about visitors to our website
              (customers and sales) in order to describe our services to
              prospective partners (advertisers, sponsors) and other reputable
              third parties and for other lawful purposes, but these statistics
              will include no personally identifiable information.<br></br><br></br>
              If you are
              concerned about your data, you have the right, subject to the
              payment of a small fee to request access to personal data which we
              may hold or process about you.<br></br><br></br>
              3. Product information<br></br><br></br>
              Unless
              otherwise indicated, all products are copyrighted by Veloworld ZA
              (Pty) Ltd and intended for personal use. Therefore, reproduction,
              modification, storage in a retrieval system or retransmission, in
              any form or by any means, electronic, mechanical or otherwise, for
              reasons other than personal use, is strictly prohibited without
              prior written permission.<br></br><br></br>
              <b>Veloworld ZA (Pty) Ltd</b> makes no warranty
              of any kind, expressed or implied, with regard to the information
              contained in its products or on its website.<br></br><br></br>
              Veloworld ZA (Pty)
              Ltd will not be liable for any loss or injury caused by
              information obtained through its products or its website.<br></br><br></br>
              4. Right
              to cancel<br></br><br></br>
              You have the right to cancel the purchase of a good or
              service without having to give a reason at any time within the
              “cooling off period” of seven working days, beginning on the day
              after purchase.<br></br><br></br>
              Once you have notified us that you wish to cancel
              the contract, any sum debited to us will be refunded to you (with
              a 15% administration fee deducted from the refund) as soon as
              possible and in any event within 30 days of your cancellation.<br></br><br></br>
              You
              will not have any right to cancel a purchase for the supply of any
              of the following goods:<br></br><br></br>
              • for the supply of audio or video
              recordings or instruction manuals.<br></br><br></br>
              • for any electronic documents
              downloaded after purchase.<br></br><br></br>
              5. Notices<br></br><br></br>
              All notices you send us must
              be sent to the contact details on this site
              <a href="https://www.veloworldza.com/contact">https://www.veloworldza.com/contact</a> We may give notice to you at
              either the email or postal address you provide to us when making a
              purchase. (Notice will be deemed received and properly served 24
              hours after an email is sent or three days after the date of
              posting of any letter.) In providing the service of any notice, it
              will be sufficient to prove, in the case of a letter, that the
              letter was properly addressed, stamped and placed in the post and,
              in the case of an email that the email was sent to the specified
              email address of the addressees.
            </Typography>
            <SocialMediaInsert></SocialMediaInsert>
          </div>
        </div>
        <Enquire className="about_enquire" />
       

        <BottomBar></BottomBar>
      </React.Fragment>
    );
  }
}

export default Terms;
