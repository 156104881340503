import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "./coaching.css";
import { Typography } from "@material-ui/core";
import BottomBar from "../bottombar/bottombar";
import SocialMediaInsert from "../socialmediainsert/socialmediainsert";

interface State {
  loading: boolean;
  activeContent: number | null;
}
interface Properties {
  api?: any;
  inEditMode: boolean;
}

class Coaching extends React.Component<Properties, State> {
  constructor(props: Properties) {
    super(props);
    this.state = {
      activeContent: null,
      loading: false,
    };
  }

  public async componentDidMount() {}

  public renderActiveContent = () => {
    switch (this.state.activeContent) {
      case null:
        return (<span>Athlete development is the ultimate, long term goal in our Veloworld coaching process.
          Coaching to achieve certain race results only is a short-term approach and not
          sustainable. We therefore focus on the development of each athlete. Development is
          measured across several criteria and measurements, including age, skills level,
          competition experience, physical condition, mental toughness and competition
          results (if the athlete competes regularly). We look at it as building blocks in the
          development of each athlete.<br></br><br></br>
          The Veloworld Development Pyramid considers all these building blocks, as well as
          information from credible research in the sports environment, to create a structure
          to guide the coaching process on each level of development. Every level in the pyramid
          must adhere to certain criteria and every athlete is assessed against these
          requirements. Testing and assessment is an important part of the coaching process,
          even for athletes who do not compete.<br></br><br></br>
          "Having experienced the vast differences in levels of athletes, even of the same age,
          has driven us to find a way of creating clusters, or levels for grouping athletes. Once
          the athletes are in the correct group, with the correct coaching approach, we have
          found an accelerated growth in their development” , according to Veloworld founder
          and head coach Marcel van der Poll.<br></br><br></br>
          If you want to find out more, or if you want to join the Veloworld coaching programs,
          please contact us via email <a style={{color:"#f05a25"}} href="mailto:marcel@veloworldza.com">marcel@veloworldza.com</a></span>)
      case 0:
        return (
          <span>
            Athlete development is the ultimate, long term goal in our Veloworld
            coaching process. Coaching to achieve certain race results only is a
            short-term approach and not sustainable.<br></br>
            <br></br>
            We therefore focus on the development of each athlete, by ensuring
            each athlete is assigned to the correct level in the Veloworld
            Development Pyramid. Our Foundation Phase is the first level of the
            pyramid, and this is where most beginner riders ages 7 to 11 fits
            in.<br></br>
            <br></br>
            Typically, these young riders have no or very little competition
            experience, and some of them have no intention to compete. They just
            want to have FUN! Coaching sessions are structured around
            development of gross motor skills, basic bicycle skills, spatial
            awareness, riding in groups, and most importantly to be safer rider.
            <br></br>
            <br></br>
            Although these sessions are planned to be lots of fun and learning
            through games, each session is carefully planned according to
            internationally researched Long Term Athlete Development guidelines,
            to ensure the correct progression of each rider
          </span>
        );
      case 1:
        return (
          <span>
            Athlete development is the ultimate, long term goal in our Veloworld
            coaching process. Coaching to achieve certain race results only is a
            short-term approach and not sustainable. We therefore focus on the
            development of each athlete, by ensuring each athlete is assigned to
            the correct level in the Veloworld Development Pyramid. <br></br>
            <br></br>Our
            Development Phase is the second level of the pyramid, and in this
            group you will find athletes for whom cycling is more than a hobby
            or recreational activity. Although they participate in other sports
            (and is highly encouraged to do so), cycling is one of their main
            sports. These athletes are usually also quite proficient in bike
            handling, displays good gross motor skills, and shows a competitive
            inclination. Some are already competing and have achieved good
            results, both on and off the bicycle.<br></br>
            <br></br> These athletes are now ready
            to develop into more competitive riders. They compete in regional
            and provincial events and are usually between 9 and 13 years old.
            Coaching sessions are structured around physical and skills
            training, with specific individual development goals. Basic fitness,
            agility and skills testing is done quarterly and recorded for each
            athlete. However the FUN element remains through group games,
            challenges and relay races of various formats. Although these
            sessions are planned to be lots of fun and learning through games,
            each session is carefully planned according to internationally
            researched Long Term Athlete Development guidelines, to ensure the
            correct progression of each rider.
          </span>
        );
      case 2:
        return (
          <span>
            Athlete development is the ultimate, long term goal in our Veloworld
            coaching process. Coaching to achieve certain race results only is a
            short-term approach and not sustainable. We therefore focus on the
            development of each athlete, by ensuring each athlete is assigned to
            the correct level in the Veloworld Development Pyramid. <br></br>
            <br></br>Our
            Performance Phase is the third level of the pyramid, and in this
            group, you will find athletes for whom cycling is their main sport,
            although not their only sport. These athletes have been competing on
            a regional, provincial and even national level, and have had fairly
            good results in these events. They are very proficient in bike
            handling and is of competitive nature.<br></br>
            <br></br> They race to achieve results
            or to win championships. Typical ages are from 14 years and older,
            including adult athletes. Coaching sessions are customized and
            structured around the physical and skills level of each athlete and
            delivered exclusively through the Veloworld Training App. <br></br>
            <br></br>Training
            is delivered on individual as well as group format, with specific
            individual development goals. Base line testing and benchmarking are
            done on a regular basis to ensure each athlete’s development is at
            the correct level. Every training plan and session is developed by
            Marcel van der Poll, UCI Level 3 Diploma coach. Although the focus
            of the Performance Group is on very specific goals and performance
            targets, each session and training plan is carefully developed
            according to internationally researched Long Term Athlete
            Development guidelines, to ensure the correct progression of each
            rider.
          </span>
        );
      case 3:
        return (
          <span>
            Athlete development is the ultimate, long term goal in our Veloworld
            coaching process. Coaching to achieve certain race results only is a
            short-term approach and not sustainable. We therefore focus on the
            development of each athlete, by ensuring each athlete is assigned to
            the correct level in the Veloworld Development Pyramid.<br></br>
            <br></br> Our
            Excellence Phase is the fourth level of the pyramid, and in this
            group, you will find athletes of 16 years and older for whom cycling
            is their main sport. These athletes also participate and compete in
            more than one cycling discipline, but they have one specific
            discipline in which they focus. <br></br>
            <br></br>They compete at provincial and
            national levels and have achieved very good results in these events.
            They are of competitive nature, and their proficiency in bike
            skills, race planning, and training are at the very top of their
            category. They race to achieve results or to win championships.
            Typical ages are from 16 years and older, including adult athletes.
            Coaching intervention are structured and customized to achieve
            expertise in the physical, skills and mental toughness aspects of
            their discipline. Training plans are delivered exclusively through
            the Veloworld Training App.<br></br>
            <br></br> Training is delivered on individual as
            well as group format, with specific individual development goals.
            Base line testing are done regularly against specific benchmarks to
            develop the athlete’s power profile and to ensure each athlete’s
            development is at the correct level.<br></br>
            <br></br> Every training plan and session
            is developed by Marcel van der Poll, UCI Level 3 Diploma coach.
            Although the focus of the Excellence Group is on becoming an expert
            in their chosen discipline, each session and training plan is
            carefully developed according to internationally researched Long
            Term Athlete Development guidelines, to ensure the correct
            progression of each rider.
          </span>
        );
      case 4:
        return (
          <span>
            Athlete development is the ultimate, long term goal in our Veloworld
            coaching process. Coaching to achieve certain race results only is a
            short-term approach and not sustainable.<br></br>
            <br></br> We therefore focus on the
            development of each athlete, by ensuring each athlete is assigned to
            the correct level in the Veloworld Development Pyramid. Our Elite
            Phase is the fifth and top level of the pyramid, and in this group,
            you will find athletes of 19 years and older for whom cycling is
            their main sport.<br></br>
            <br></br> These athletes train and compete mostly in one
            cycling discipline, but they also have one or two other disciplines
            which they participate in or compete in when out of competition with
            their main discipline. They compete at provincial and national
            levels, with very good race results, but they now start to prepare
            to compete internationally. They are of competitive nature, and
            their proficiency in bike skills, race planning, and training are at
            the very top of their category. They race to achieve results or to
            win championships locally but also internationally. <br></br>
            <br></br>Typical ages are
            from 19 years and older, including adult athletes. Coaching
            intervention are structured and customized to achieve expertise in
            the physical, skills and mental toughness aspects of their
            discipline. Training plans are delivered exclusively through the
            Veloworld Training App. Training is delivered on individual as well
            as group format, with specific individual development goals. Base
            line testing are done regularly against specific benchmarks to
            develop the athlete’s power profile and to ensure each athlete’s
            development is at the correct level.<br></br>
            <br></br> Every training plan and session
            is developed by Marcel van der Poll, UCI Level 3 Diploma coach.
            Furthermore, Veloworld offers athlete management and career planning
            assistance to these athletes through a network of professional
            consultants. Although the focus of the Elite Group is on becoming an
            internationally successful athlete in their chosen discipline, each
            session and training plan is carefully developed according to
            internationally researched Long Term
          </span>
        );
    }
  };

  public renderActiveContentHeader = () => {
    switch (this.state.activeContent) {
      case null:
        return <span></span>;
      case 0:
        return <span>Foundation Phase</span>;
      case 1:
        return <span>Development Phase</span>;
      case 2:
        return <span>Performance Phase</span>;
      case 3:
        return <span>Excellence Phase</span>;
      case 4:
        return <span>Elite Phase</span>;
    }
  };

  private loadInstagram = () => {
    window.open("https://www.instagram.com/veloworldza/");
  };

  private loadFacebook = () => {
    window.open("https://www.facebook.com/veloworldza/");
  };

  private setActiveSection = (section: number| null)=> {
    if (section===null) {
      return;
    }
    this.setState({activeContent: section});
  }
  render() {
    return (
      <React.Fragment>
        <div className="coaching_main">
          <div>
            <Typography className="coaching_header">
              Coaching: The Veloworld Development Pyramid
            </Typography>
          </div>
          <div className="coaching_pyramidcontainer">
            <div className="coaching_copy">
              <React.Fragment>
                <Typography className="coaching_contentHeader">
                  {this.renderActiveContentHeader()}
                </Typography>
                <Typography className="coaching_contentText">
                  {this.renderActiveContent()}
                </Typography>
                <SocialMediaInsert></SocialMediaInsert>
              </React.Fragment>
            </div>
            <div className="coaching_pyramid">
              <img className="coaching_hoverImage" src={require("../../content/coachingHover.jpg")} ></img>
              <div className="coaching_pyramidPart" onMouseOut={this.setActiveSection.bind(this,null)} onMouseOver={this.setActiveSection.bind(this,4)}>
                <img
                  className="coaching_pyramid_4"
                  src={require("../../content/pyramid4.png")}
                />
                <Typography className="coaching_4Text">ELITE</Typography>
              </div>
              <div className="coaching_pyramidPart" onMouseOut={this.setActiveSection.bind(this,null)} onMouseOver={this.setActiveSection.bind(this,3)}>
                <img
                  className="coaching_pyramid_3"
                  src={require("../../content/pyramid3.png")}
                />
                <Typography className="coaching_3Text">EXCELLENCE</Typography>
              </div>
              <div className="coaching_pyramidPart" onMouseOut={this.setActiveSection.bind(this,null)} onMouseOver={this.setActiveSection.bind(this,2)}>
                <img
                  className="coaching_pyramid_2"
                  src={require("../../content/pyramid2.png")}
                />
                <Typography className="coaching_2Text">PERFORMANCE</Typography>
              </div>
              <div className="coaching_pyramidPart" onMouseOut={this.setActiveSection.bind(this,null)} onMouseOver={this.setActiveSection.bind(this,1)}>
                <img
                  className="coaching_pyramid_1"
                  src={require("../../content/pyramid1.png")}
                />
                <Typography className="coaching_1Text">DEVELOPMENT</Typography>
              </div>
              <div className="coaching_pyramidPart" onMouseOut={this.setActiveSection.bind(this,null)} onMouseOver={this.setActiveSection.bind(this,0)}>
                <img
                  className="coaching_pyramid_0"
                  src={require("../../content/pyramid0.png")}
                />
                <Typography  className="coaching_0Text">FOUNDATION</Typography>
              </div>
            </div>
          </div>
        </div>
        <BottomBar></BottomBar>
      </React.Fragment>
    );
  }
}

export default Coaching;
