// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../content/paperbg.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../content/barbg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".pagecontent-main {\r\n  display: flex;\r\n  width: 100%;\r\n  justify-content: center;\r\n}\r\n.pagecontent-paper {\r\n  width: 80%;\r\n  height: calc(100vh - 110px);\r\n  overflow: auto;\r\n  margin-top: 10px;\r\n  padding: 20px;\r\n  min-width: 800px;\r\n  background-color: transparent !important;\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  z-index: 9;\r\n}\r\n.ae-placeholder {\r\n  opacity: 1;\r\n}\r\n.blog-post {\r\n  margin-bottom: 50px;\r\n}\r\n.blog-summaryPost {\r\n  height: 30px;\r\n}\r\n.blog-editpostbutton {\r\n  margin-right: 30px !important;\r\n}\r\n.blog-addpostbutton {\r\n  margin-bottom: 40px;\r\n}\r\n.blog-titlefield {\r\n  width: 100%;\r\n}\r\n.blog-editrow {\r\n  margin-top: 20px;\r\n  margin-bottom: 40px;\r\n}\r\n.blog-editbuttonrow {\r\n  display: flex;\r\n  width: 400px;\r\n}\r\n.blog-savepostbutton {\r\n  margin-right: 40px;\r\n}\r\n.blog-readOnlyEditor {\r\n  height: 200px;\r\n  overflow: hidden !important;\r\n  text-overflow: ellipsis;\r\n}\r\n.blog-postitem {\r\n  position: relative;\r\n}\r\n.blog-readMoreContainer {\r\n  height: 50px;\r\n  position: absolute;\r\n  bottom: 0px;\r\n  width: 100%;\r\n  border: 1px solid #ece9e9;\r\n  margin: 10px;\r\n  margin-bottom: 0px;\r\n  border-left: none;\r\n  border-right: none;\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n.blog-deleteButton {\r\n  color: #921313 !important;\r\n}\r\n.blog-addButton {\r\n  color: #137520 !important;\r\n}\r\n", ""]);
// Exports
module.exports = exports;
