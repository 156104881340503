import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "./qualifylogin.css";
import { Typography } from "@material-ui/core";
import PageContent from "../../../pagecontent/pagecontent";
import BottomBar from "../../../bottombar/bottombar";
import SocialMediaInsert from "../../../socialmediainsert/socialmediainsert";
import Enquire from "../../../home/components/enquire/enquire";
import QualifyButtons from "../qualifybuttons/qualifybuttons";

interface State {
  loading: boolean;
}
interface Properties {
  api?: any;
  inEditMode: boolean;
  onNavigate: (location:string)=>void;
}

class QualifyLogin extends React.Component<Properties, State> {
  constructor(props: Properties) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public async componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="qualifylogin_main">
          <div>
            <Typography className="qualifylogin_header">
            Log in as a student
            </Typography>
          </div>
          <div className="qualifylogin_text">
            <Typography className="qualifylogin_contentText">
            Log in functionality will be enabled once the course dates are finalized and students have registered.
            </Typography>
            <SocialMediaInsert></SocialMediaInsert>
            <QualifyButtons  onNavigate={this.props.onNavigate}></QualifyButtons>
          </div>
        </div>
        <Enquire className="qualifylogin_enquire" />
        <img
          className="qualifylogin_image"
          src={require("../../../../content/gal2.jpg")}
        ></img>

        <BottomBar></BottomBar>
      </React.Fragment>
    );
  }
}

export default QualifyLogin;
