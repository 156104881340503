import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import "./index.css";
var WebFont = require('webfontloader');
 
WebFont.load({
  custom: {
      families: ['Antonio-Regular'],
      urls: ['./content/fonts/stylesheet.css']
    }
});
ReactDOM.render(<App />, document.getElementById("app"));
