import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "./home.css";
import { Typography, Paper } from "@material-ui/core";
import PageContent from "../pagecontent/pagecontent";
import Events from "./components/events/events";
import BlogPosts from "./components/blogposts/blogposts";
import moment from "moment";
import Loader from "../loader/loader";
import BottomBar from "../bottombar/bottombar";
import Enquire from "./components/enquire/enquire";
import GalleryBox from "./components/gallerybox/gallerybox";
interface State {
  loading: boolean;
}
interface Properties {
  api?: any;
  inEditMode: boolean;
  onNavigate: (oath: string) => void;
}

class Home extends React.Component<Properties, State> {
  constructor(props: Properties) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public async componentDidMount() {}

  private galleryClick = (path: string) => {
    console.log("clicked gall");
    this.props.onNavigate(path);
  };

  render() {
    return (
      <React.Fragment>
        <div className="home-main">
          <div className="home-content">
            <div className="home_horizontalDivider"> </div>
            <div className="home_trainWithPurposeHeader">
              <Typography variant="h1" className="home_headerText">
                TRAIN WITH PURPOSE
              </Typography>
            </div>
            <div className="home_trainWithPurpose">
              <div className="home_trainWithPurposeContentBar"> </div>
              <div className="home_trainWithPurposeContent">
                <div className="home_trainWording">
                  <div>
                    Veloworld ZA (PTY) Ltd is a cycling coaching business based
                    in Cape Town, South Africa. The company specializes in
                    coaching and development across different disciplines,
                    categories and ages. Business activities include development
                    coaching (schools’ level), performance coaching of
                    individuals and groups, and training cycling & other coaches
                    through an accredited training course. Coaching services
                    include schools’ groups, individual assessments,
                    personalised training programs for high performance
                    individuals, and training camps. Founder Marcel van der Poll
                    is a UCI Level 3 Diploma coach and veteran of many cycling
                    events, including a Cape Epic top 100 finish.
                  </div>
                  <div>
                    <Button
                      className="home_findoutmorebutton"
                      onClick={this.galleryClick.bind(this, "coaching")}
                    >
                      FIND OUT MORE
                    </Button>
                  </div>
                </div>
                <img
                  className="home_trainWithPurposeImage"
                  src={require("../../content/home_bw.jpg")}
                ></img>
              </div>
            </div>

            <div className="home_contentRow">
              <Events
                className="home_events"
                inEditMode={this.props.inEditMode}
              ></Events>
              <div className="home_spacer">&nbsp;</div>
              <Enquire api={this.props.api} className="home_enquire" />
            </div>

            <div className="home_grid">
              <GalleryBox
                className="home_gridItem1 home_gridItem"
                text="COACHING APP"
                onClick={this.galleryClick.bind(this, "coachingapp")}
                background={
                  <img
                    className="home_gridItemImage"
                    src={require("../../content/gal1.jpg")}
                  />
                }
                icon={<img src={require("../../content/watch.png")} />}
              ></GalleryBox>
              <GalleryBox
                className="home_gridItem2 home_gridItem"
                text="COACHING"
                onClick={this.galleryClick.bind(this, "coaching")}
                background={
                  <img
                    className="home_gridItemImage"
                    src={require("../../content/gal4.jpg")}
                  />
                }
                icon={<img src={require("../../content/shoe.png")} />}
              ></GalleryBox>
              <GalleryBox
                className="home_gridItem3 home_gridItem"
                text="QUALIFY AS A COACH"
                onClick={this.galleryClick.bind(this, "qualifyhow")}
                background={
                  <img
                    className="home_gridItemImage"
                    src={require("../../content/gal2.jpg")}
                  />
                }
                icon={<img src={require("../../content/glove.png")} />}
              ></GalleryBox>
              <GalleryBox
                className="home_gridItem4 home_gridItem"
                text="ONLINE SHOP"
                onClick={this.galleryClick.bind(this, "shop")}
                background={
                  <img
                    className="home_gridItemImage"
                    src={require("../../content/gal3.jpg")}
                  />
                }
                icon={<img src={require("../../content/shirt.png")} />}
              ></GalleryBox>
              <GalleryBox
                className="home_gridItem5 home_gridItem"
                text="GALLERY"
                onClick={this.galleryClick.bind(this, "gallery")}
                background={
                  <img
                    className="home_gridItemImage"
                    src={require("../../content/gal6.jpg")}
                  />
                }
                icon={<img src={require("../../content/glasses.png")} />}
              ></GalleryBox>
              <GalleryBox
                className="home_gridItem6 home_gridItem"
                text="LATEST BLOGS"
                onClick={this.galleryClick.bind(this, "blog")}
                background={
                  <img
                    className="home_gridItemImage"
                    src={require("../../content/gal5.jpg")}
                  />
                }
                icon={<img src={require("../../content/bell.png")} />}
              ></GalleryBox>
            </div>
          </div>
        </div>
        <BottomBar></BottomBar>
      </React.Fragment>
    );
  }
}

export default Home;
