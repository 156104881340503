// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./content/bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "body {\r\n  margin: 0px;\r\n  padding: 0px;\r\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\r\n  background-size: cover;\r\n  background-position-y: 0px;\r\n  font-family: \"Antonio-Regular\" !important;\r\n  overflow: hidden;\r\n}\r\n.ae-toolbar-add {\r\n  z-index: 99;\r\n}\r\n.alloy-editor-visible {\r\n  z-index: 99;\r\n}\r\np {\r\n  font-family: \"Antonio-Regular\" !important;\r\n}\r\ndiv {\r\n  font-family: \"Antonio-Regular\" !important;\r\n}\r\nli {\r\n  font-family: \"Antonio-Regular\" !important;\r\n}\r\na {\r\n  font-family: \"Antonio-Regular\" !important;\r\n  color:#f05a25 !important;\r\n}\r\nh1 h2 h3 h4 h5 h6 body {\r\n  font-family: \"Antonio-Regular\" !important;\r\n}\r\n.MuiTypography-h6{\r\n  font-family: \"Antonio-Regular\" !important;\r\n}\r\n.MuiTypography-h5{\r\n  font-family: \"Antonio-Regular\" !important;\r\n}\r\n.MuiTypography-h4{\r\n  font-family: \"Antonio-Regular\" !important;\r\n}\r\n.MuiTypography-h3{\r\n  font-family: \"Antonio-Regular\" !important;\r\n}\r\n.MuiTypography-h2{\r\n  font-family: \"Antonio-Regular\" !important;\r\n}\r\n.MuiTypography-h1{\r\n  font-family: \"Antonio-Regular\" !important;\r\n}\r\n.MuiButton-root {\r\n  border-radius: 0px !important;\r\n}", ""]);
// Exports
module.exports = exports;
