// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../content/paperbg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".pagecontent-main {\r\n  display: flex;\r\n  width: 100%;\r\n  justify-content: center;\r\n}\r\n.pagecontent-paper {\r\n  width: 80%;\r\n  height: calc(100vh - 110px);\r\n  overflow: auto;\r\n  margin-top: 10px;\r\n  padding: 20px;\r\n  min-width: 800px;\r\n  background-color: transparent !important;\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  z-index: 9;\r\n}\r\n.ae-placeholder {\r\n  opacity: 1;\r\n}\r\n.ck-content {\r\n  border: none !important;\r\n  background: transparent !important;\r\n}\r\n", ""]);
// Exports
module.exports = exports;
