import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "./qualifybuttons.css";
import { Typography } from "@material-ui/core";
import PageContent from "../../../pagecontent/pagecontent";
import BottomBar from "../../../bottombar/bottombar";
import SocialMediaInsert from "../../../socialmediainsert/socialmediainsert";
import Enquire from "../../../home/components/enquire/enquire";

interface State {
  loading: boolean;
}
interface Properties {
  api?: any;
  onNavigate: (location:string)=>void;
}

class QualifyButtons extends React.Component<Properties, State> {
  constructor(props: Properties) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public async componentDidMount() {}

  private handleClick = (location:string) => {
    this.props.onNavigate(location);
  }

  render() {
    return (
      <div className="qualifybuttons_buttons">
        <Button className="qualifybuttons_button" onClick={this.handleClick.bind(this,"qualifyoutline")}>
          COURSE OUTLINE & ACCREDITATION
        </Button>
        <Button className="qualifybuttons_button" onClick={this.handleClick.bind(this,"qualifydate")}>
          COURSE DATES & SCHEDULING
        </Button>
        <Button className="qualifybuttons_button" onClick={this.handleClick.bind(this,"qualifyfee")}>COURSE FEES</Button>
        <Button className="qualifybuttons_button" onClick={this.handleClick.bind(this,"qualifylogin")}>LOG IN AS A STUDENT</Button>
      </div>
    );
  }
}

export default QualifyButtons;
