import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "./qualify.css";
import { Typography } from "@material-ui/core";
import PageContent from "../pagecontent/pagecontent";
import BottomBar from "../bottombar/bottombar";
import SocialMediaInsert from "../socialmediainsert/socialmediainsert";
import Enquire from "../home/components/enquire/enquire";
import QualifyButtons from "./components/qualifybuttons/qualifybuttons";

interface State {
  loading: boolean;
}
interface Properties {
  api?: any;
  inEditMode: boolean;
  onNavigate: (location:string)=>void;
}

class Qualify extends React.Component<Properties, State> {
  constructor(props: Properties) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public async componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="qualify_main">
          <div>
            <Typography className="qualify_header">
              How to qualify as a coach
            </Typography>
          </div>
          <div className="qualify_text">
            <Typography className="qualify_contentText">
              Veloworld is a cycling company Veloworld ZA (PTY) Ltd is a cycling
              coaching business based in Cape Town, South Africa. The company
              specializes in coaching and development across different
              disciplines, categories and ages. Business activities include
              development coaching (schools’ level), performance coaching of
              individuals and groups, and training cycling & other coaches
              through an accredited training course.<br></br>
              <br></br> Veloworld ZA (PTY) Ltd is registered with The Culture,
              Art, Tourism, Hospitality, and Sport Sector Education and Training
              Authority (CATHSSETA) and offers an accredited NQF Level 4 Course
              in Cycling Coaching. Click here for the course outline. <br></br>
              <br></br>The company developed a training, coaching, learning and
              booking platform which forms the communication hub of the company.
              Athletes can access their training plans on it and provide
              feedback through a unique athlete feedback mechanism. Coaches can
              use the platform to develop and prescribe, monitor and adjust
              training for athletes. Clients (athletes & parents) can book and
              pay for training sessions and purchase merchandise. All in one
              platform!<br></br>
              <br></br> Veloworld ZA (PTY) Ltd was registered in July 2015 at
              the South African Registrar of Companies. Registration number is
              2015/218097/07. <br></br>
              <br></br>Coaching services include schools’ groups, individual
              assessments, personalised training programs for high performance
              individuals, and training camps. Founder Marcel van der Poll is a
              UCI Level 3 Diploma coach and veteran of many cycling events,
              including a Cape Epic top 100 finish.
            </Typography>
            <SocialMediaInsert></SocialMediaInsert>
            <QualifyButtons onNavigate={this.props.onNavigate}></QualifyButtons>
          </div>
        </div>
        <Enquire className="qualify_enquire" />
        <img
          className="qualify_image"
          src={require("../../content/gal2.jpg")}
        ></img>

        <BottomBar></BottomBar>
      </React.Fragment>
    );
  }
}

export default Qualify;
