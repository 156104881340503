import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "./events.css";
import { Typography, ThemeProvider, Paper, TextField } from "@material-ui/core";
import { Api } from "../../../../api/api";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Countdown } from "../countdown/countdown";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  DateTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

interface State {
  loading: boolean;
  event: any;
  editEvent: any;
}
interface Properties {
  inEditMode: boolean;
  className?: string;
}

class Events extends React.Component<Properties, State> {
  constructor(props: Properties) {
    super(props);
    this.state = {
      loading: false,
      event: null,
      editEvent: null,
    };
  }

  public async componentDidMount() {
    const api = new Api();
    const event = await api.getNextEvent();
    this.setState({ event: event && event.length > 0 ? event[0] : null });
  }

  private addEvent = async () => {
    const api = new Api();
    const event = await api.addEvent({
      id: null,
      title: "new event",
      subTitle: "subtitle goes here",
      startDate: new Date(),
    });
    const evt = await api.getNextEvent();
    this.setState({ event: evt });
  };

  private updateEvent = async () => {
    const api = new Api();
    const event = Object.assign({}, this.state.event);
    await api.addEvent(event);
    this.setState({ editEvent: null });
  };

  private deleteEvent = async (id: number) => {
    const api = new Api();
    try {
      const event = await api.deleteEvent(id);
      const evt = await api.getNextEvent();
      this.setState({ event: evt });
    } catch (e) {}
  };

  private editEvent(event: any) {
    this.setState({ editEvent: event });
  }

  private updateTitle = (e: any) => {
    const event = Object.assign({}, this.state.event);
    event.title = e.target.value;
    this.setState({ editEvent: event, event: event });
  };
  private updateSubTitle = (e: any) => {
    const event = Object.assign({}, this.state.event);
    event.subTitle = e.target.value;
    this.setState({ editEvent: event, event: event });
  };
  private updateStartDate = (dateVal: any) => {
    const event = Object.assign({}, this.state.event);
    event.startDate = dateVal.toDate();
    this.setState({ editEvent: event, event: event });
  };

  private renderEvent = () => {
    if (!this.state.event || this.state.event.length === 0) {
      return (
        <div className="events_noevent">
          <div className="events-title">There are no upcoming events</div>
        </div>
      );
    }
    const event = this.state.event;
    return (
      <div className="events_event" key={"event_" + event.id}>
        <div className="events-title">
          <div className="events-title">
            <Typography>{event.title}</Typography>
          </div>
          {this.props.inEditMode ? (
            <div className="events-editIcons">
              <EditIcon
                className="events-editIcon"
                onClick={this.editEvent.bind(this, event)}
              ></EditIcon>{" "}
              <DeleteIcon
                className="events-deleteIcon"
                onClick={this.deleteEvent.bind(this, event.id)}
              ></DeleteIcon>
            </div>
          ) : null}
        </div>
        <div className="events-subTitle">
          <Typography>
            {moment(event.startDate).format("DD MMMM YYYY")}
          </Typography>
        </div>
        <Countdown targetDateTime={event.startDate}></Countdown>
      </div>
    );
  };

  private renderEditMode = () => {
    return (
      <div className="events-eventsContainer">
        {!this.state.editEvent ? (
          <React.Fragment>
            {this.renderEvent()}
            <div>
              <Button className="events-button" onClick={this.addEvent}>
                Add Event
              </Button>
            </div>{" "}
          </React.Fragment>
        ) : (
          <div>
            <div className="events-editRow">
              Title:{" "}
              <TextField
                variant="outlined"
                placeholder="Event Title"
                value={this.state.editEvent.title}
                onChange={this.updateTitle}
              ></TextField>
            </div>
            <div className="events-editRow">
              Sub Title:{" "}
              <TextField
                variant="outlined"
                placeholder="Event Sub Title"
                value={this.state.editEvent.subTitle}
                onChange={this.updateSubTitle}
              ></TextField>
            </div>
            <div className="events-editRow">
              Start Date:{" "}
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DateTimePicker
                  disablePast
                  label="With Today Button"
                  showTodayButton
                  value={this.state.editEvent.startDate}
                  onChange={this.updateStartDate}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              {" "}
              <Button className="events-button" onClick={this.updateEvent}>
                Update
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };

  private renderNormalMode = () => {
    return (
      <div className="events-eventsContainer">
        <React.Fragment>{this.renderEvent()}</React.Fragment>
      </div>
    );
  };

  render() {
    return (
      <div className={this.props.className}>
        <div className="events-bg"></div>
        <div className="events-header">
          <img src={require("../../../../content/bicycle.png")}></img>
          <Typography variant="h5" className="events-upcoming">
            UPCOMING EVENTS
          </Typography>
        </div>
        <div className="events_layout">
          {this.props.inEditMode
            ? this.renderEditMode()
            : this.renderNormalMode()}
          <div className="events_moreContainer">
            <Typography className="events_moreChevron">{">"}</Typography><Typography className="events_moreText">{"MORE EVENTS"}</Typography>
          </div>
        </div>
      </div>
    );
  }
}

export default Events;
