import React, { Component } from "react";
import "./gallery.css";
import { Typography } from "@material-ui/core";
import BottomBar from "../bottombar/bottombar";
import Enquire from "../home/components/enquire/enquire";
import { SRLWrapper } from "simple-react-lightbox";

interface State {
  loading: boolean;
  isOpen: boolean;
}
interface Properties {
  api?: any;
  inEditMode: boolean;
}

var listOfImages: any = [];

class Gallery extends React.Component<Properties, State> {
  constructor(props: Properties) {
    super(props);
    this.state = {
      loading: false,
      isOpen: true,
    };
  }

  public async componentDidMount() {   
  }


  private renderImages = (): JSX.Element => {
    const listOfImages = require.context("../../content/gallery/", true, /\.(png|jpe?g|svg)$/,"eager");
    const images = [];
    let index = 0;
    for (const img of listOfImages.keys()) {
      images.push(<img className="gallery_image" key={index} src={require(`../../content/gallery/${img.split("/").pop()}`)} ></img>);
      index++;
    }
    return <SRLWrapper>{images}</SRLWrapper>;
  };

  render() {
    return (
      <React.Fragment>
        <div className="gallery_main">
          <div>
            <Typography className="gallery_header">Gallery</Typography>
          </div>
          <div className="gallery_items">{this.renderImages()}</div>
        </div>
        <Enquire className="gallery_enquire" />

        <BottomBar></BottomBar>
      </React.Fragment>
    );
  }
}

export default Gallery;
